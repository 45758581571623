import { Basket, BasketActions } from '../model/basket-model';

const noAction = async () => undefined;

export const createNoActionBasketStub = (): Basket => ({
    loading: false,
    addToBasket: noAction,
    removeFromBasket: noAction,
    setAmount: noAction,
    setAddressInfo: noAction,
    clearErrors: noAction,
    openMiniBasket: noAction,
    closeMiniBasket: noAction,
});

export const createBasket = (actions: BasketActions, skipInitialization = false): Basket => ({
    initializing: !skipInitialization,
    loading: !skipInitialization,
    ...actions,
});
