import { IS_BROWSER } from '../../constants'

type EventType =
  | 'page_view'
  | 'click'
  | 'accordion_open'
  | 'form_submit'
  | 'form_initiate'

export type PageInfo = {
  pageName: string
  siteSection: string
  subSection1: string
  subSection2?: string
  subSection3?: string
  pageVariant?: 'DEFAULT' | 'SMB' | 'ENTERPRISE'
  pageType: string
  id: string
}

export type FormattedPageInfo = PageInfo & {
  adformPageName: string
  divisionSection: string
  urlWithoutQuery: string
  fullURL: string
}

type item = {
  name: string
  id: string
  quantity: string
  price: string
  brand: string
}

export type ProductsInfo = {
  items: Array<item>
  customEvent: string
}

export type FormInfo = {
  name: string
  formSubmission?: string
  fullUrl?: string
  type?: string
}

export type AccordianInfo = {
  name: string
  position: string
}

export type OrderInfo = {
  id: string
  revenueExcludingVatAndDelivery: string
  revenue: string
}

export type SearchInfo = {
  term: string
  resultCount: number
}

type ClickEventData = {
  [k: string]: string //for now just let this be anything
}

type TrackingEvent = {
  event: EventType
  pageInfo?: FormattedPageInfo | ClickEventData
  products?: ProductsInfo
  accordion?: AccordianInfo
  formInfo?: FormInfo
  orderInfo?: OrderInfo
  searchInfo?: SearchInfo
}

const trackingEvent = ({
  event,
  pageInfo,
  products,
  accordion,
  formInfo,
  orderInfo,
  searchInfo,
}: TrackingEvent) => {
  if (!IS_BROWSER) return
  const adobeDataLayer = window?.adobeDataLayer || []
  adobeDataLayer.push({
    'event': event,
    ...(pageInfo && { 'pageInfo': pageInfo }),
    ...(products && { 'products': products }),
    ...(accordion && { 'accordion': accordion }),
    ...(formInfo && { 'form': formInfo }),
    ...(orderInfo && { 'orderInfo': orderInfo }),
    ...(searchInfo && {
      'search': searchInfo,
      'customEvent': 'internal_search',
    }),
  })
  window.adobeDataLayer = adobeDataLayer
}

export default trackingEvent
