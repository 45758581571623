import React, { ReactElement, useEffect } from 'react';
import { translate } from '../../helpers/translate';
import { useBasket } from '../../modules/basket';
import { useNotifications } from '../../modules/notifications';

export const BasketErrorHandler = (): ReactElement => {
    const { setAmount, removeFromBasket, invalidVariants, unknownError, lines, clearErrors } = useBasket();
    const notifications = useNotifications();

    useEffect(() => {
        if (unknownError) {
            notifications.addNotification(translate('basket.errors.general'));
        }
        if (invalidVariants) {
            invalidVariants.forEach((invalidVariant) => {
                if (!invalidVariant.variantId) {
                    return;
                }

                const productReference =
                    lines?.find(({ variantId }) => variantId === invalidVariant.variantId)?.name ??
                    invalidVariant.variantId;

                if (
                    typeof invalidVariant.remainingStock === 'number' &&
                    typeof invalidVariant.requestedStock === 'number' &&
                    invalidVariant.remainingStock > 0
                ) {
                    notifications.addNotification(
                        translate('basket.errors.insufficientStock', {
                            productReference,
                            amountRemoved: String(invalidVariant.requestedStock - invalidVariant.remainingStock),
                        }),
                    );
                    setAmount(invalidVariant.variantId, invalidVariant.remainingStock);
                } else {
                    notifications.addNotification(
                        translate('basket.errors.productUnavailable', {
                            productReference,
                        }),
                    );
                    removeFromBasket(invalidVariant.variantId as string);
                }
            });
        }
        if (invalidVariants || unknownError) {
            clearErrors();
        }
    }, [invalidVariants, unknownError]);

    return <></>;
};
