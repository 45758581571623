import React, { useState, useRef, useEffect } from 'react'
import { IMobileMenu } from '.'
import MegaMenu from '../MegaMenu'
import SearchMenu from '../SearchMenu'
import NavigationItem from '../NavigationItem'
import { INavItems } from '../../Navigation.types'
import { iconsMap } from '@tdcerhverv/parrotfish/dist/icons/icons'

//MUI components
import { makeStyles } from '@material-ui/styles'
import Divider from '@material-ui/core/Divider'
import LoginMenu from '../LoginMenu'

const useStyles = makeStyles({
  root: {
    padding: '0 24px',
  },
  mainNavigation: {
    padding: 0,
    margin: 0,
    marginTop: -12,

    '& > li': {
      padding: 0,
      marginBottom: 14,
    },
  },
  menuDivider: {
    margin: '24px 0',
  },
})

const MobileMenu = ({ navItems }: IMobileMenu) => {
  const ref = useRef(null)
  const [activeNav, setActiveNav] = useState<number | null>(null)
  const [searchOpen, setSearchOpen] = useState<boolean>(false)
  const [loginMenuOpen, setLoginMenuOpen] = useState<boolean>(false)
  const mainNavigationView = Boolean(activeNav) || searchOpen || loginMenuOpen

  const handleActiveNav = (navItem: number) => {
    if (activeNav && activeNav === navItem + 1) {
      setActiveNav(null)
    } else {
      setActiveNav(navItem + 1)
    }
  }

  const handleSearchOpen = () => {
    setSearchOpen(!searchOpen)
  }

  const handleLoginMenu = () => {
    setLoginMenuOpen(!loginMenuOpen)
  }

  useEffect(() => {
    if (!mainNavigationView) {
      setActiveNav(null)
      setSearchOpen(false)
      setLoginMenuOpen(false)
    }
  }, [mainNavigationView])

  const classes = useStyles()

  return (
    <div className={classes.root}>
      {!mainNavigationView && (
        <ul ref={ref} className={classes.mainNavigation}>
          {navItems.map((navItem: INavItems, index: number) => (
            <NavigationItem
              key={index}
              {...navItem}
              index={index}
              active={index === activeNav - 1}
              onClick={handleActiveNav}
              isAnimated={true}
            />
          ))}
          <Divider className={classes.menuDivider} />
          <NavigationItem
            leftIcon={iconsMap['Search']}
            onClick={handleSearchOpen}
            title="Søg"
            isAnimated={true}
          />
          <NavigationItem title="Kontakt" href="/kontakt" isAnimated={true} />
          <NavigationItem
            title="Hjælp"
            href="/kundeservice"
            isAnimated={true}
          />
          <NavigationItem
            leftIcon={iconsMap['Lock']}
            onClick={handleLoginMenu}
            title="Log ind"
            isAnimated={true}
          />
        </ul>
      )}
      {activeNav && (
        <>
          {navItems.map((navItem, index) => (
            <MegaMenu
              key={index}
              handleClose={setActiveNav}
              navItem={navItem}
              active={activeNav - 1 === index}
            />
          ))}
        </>
      )}
      {searchOpen && (
        <SearchMenu handleClose={setSearchOpen} open={searchOpen} />
      )}
      {loginMenuOpen && <LoginMenu handleClose={setLoginMenuOpen} />}
    </div>
  )
}

export default MobileMenu
