import React, { ReactElement } from 'react'
import { Button, makeStyles, Slide, Typography } from '@material-ui/core'
import ShoppingCartIcon from '../../icons/ShoppingCart'
import { useBasket } from '../../modules/basket'
import { translate } from '../../helpers/translate'
import { ExpandRightIcon } from '../../icons/generated/ExpandRightIcon'

const useStyles = makeStyles(theme => ({
  wrapper: {
    position: 'sticky',
    bottom: theme.spacing(5),
    display: 'flex',
    justifyContent: 'center',
    zIndex: 1,
    pointerEvents: 'none',
  },
  cartWrapper: {
    position: 'relative',
    marginRight: theme.spacing(0.5),
  },
  button: {
    pointerEvents: 'auto',
    height: theme.spacing(6.75),
    borderRadius: '50px',
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(2),
  },
  cartIcon: {
    marginRight: theme.spacing(2),
  },
  arrowIcon: {
    marginLeft: theme.spacing(3),
    fontSize: '16px',
  },
  badgeWrapper: {
    width: theme.spacing(2),
    height: theme.spacing(2),
    position: 'absolute',
    top: theme.spacing(-1),
    right: theme.spacing(1),
    background: theme.palette.error.main,
    borderRadius: '50%',
  },
  badge: {
    marginTop: theme.spacing(0.25),
    width: '100%',
    color: theme.palette.primary.contrastText,
    fontSize: 9,
    textAlign: 'center',
    whiteSpace: 'nowrap',
  },
  loadingIcon: {
    color: theme.palette.primary.contrastText,
  },
}))

export const MiniBasketButton = (): ReactElement => {
  const basket = useBasket()
  const totalProductsCount = basket.amount ?? 0
  const hasProducts = totalProductsCount > 0
  const classes = useStyles()

  return (
    <>
      <div className={classes.wrapper}>
        <Slide direction="up" in={hasProducts}>
          <Button
            className={classes.button}
            onClick={() => {
              if (basket.initializing) {
                return
              }
              basket.openMiniBasket()
            }}
          >
            <div className={classes.cartWrapper}>
              <ShoppingCartIcon className={classes.cartIcon} />
              {!!totalProductsCount && (
                <div className={classes.badgeWrapper}>
                  <Typography variant="srOnly">
                    {translate('basket.itemsInBasket')}
                  </Typography>
                  <Typography
                    variant="caption"
                    component="div"
                    className={classes.badge}
                  >
                    {totalProductsCount}
                  </Typography>
                </div>
              )}
            </div>
            <Typography variant="h6" component="span">
              {translate('basket.miniBasketButton')}
            </Typography>
            <ExpandRightIcon className={classes.arrowIcon} />
          </Button>
        </Slide>
      </div>
    </>
  )
}
