import { IS_BROWSER } from "../../constants";

type IFunctionReturnType = {
    get: (keyName: string) => string | null;
    set: (keyName: string, value: string) => void;
    remove: (keyName: string) => void;
};

const createStorageFunctions = (type: 'sessionStorage' | 'localStorage'): IFunctionReturnType => {
    const storageAvailable = IS_BROWSER && window[type] !== undefined;

    return {
        set: (key: string, value: string): void => {
            if (storageAvailable) {
                window[type].setItem(key, value);
            }
        },
        get: (key: string): string | null => {
            if (storageAvailable) {
                return window[type].getItem(key);
            }

            return null;
        },
        remove: (key: string): void => {
            if (storageAvailable) {
                window[type].removeItem(key);
            }
        },
    };
};

export const {
    get: getLocalStorageItem,
    set: setLocalStorageItem,
    remove: removeLocalStorageItem,
} = createStorageFunctions('localStorage');

export const {
    get: getSessionStorageItem,
    set: setSessionStorageItem,
    remove: removeSessionStorageItem,
} = createStorageFunctions('sessionStorage');
