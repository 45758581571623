import React, { ReactElement } from 'react'

// MUI
import { makeStyles } from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import IconButton from '@material-ui/core/IconButton'
import SvgIcon from '@material-ui/core/SvgIcon'
import Typography from '@material-ui/core/Typography'

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
//@ts-ignore
import Facebook from '@tdcerhverv/parrotfish/dist/icons/Icons/Facebook.svg'
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
//@ts-ignore
import Linkedin from '@tdcerhverv/parrotfish/dist/icons/Icons/LinkedIn.svg'
import Instagram from '../../../../static/Instagram.svg'
const useStyles = makeStyles(theme => ({
  titles: {
    marginBottom: 16,
  },
  socialIconButton: {
    padding: 6,
  },
  socialIconButtonsContainer: {
    marginLeft: '-6px',
  },
  [theme.breakpoints.down('sm')]: {
    socialIconButtonsContainer: {
      marginBottom: 5,
    },
  },
}))

const InformationColumn = (): ReactElement => {
  const classes = useStyles()
  return (
    <Grid item xs={12} md={6} lg={2}>
      <Typography className={classes.titles} component="h2" variant="subtitle1">
        TDC Erhverv
      </Typography>
      <Typography variant="body2">
        Teglholmsgade 1<br />
        2450, København SV
        <br />
        CVR 40075291
      </Typography>
      <Box mt={3.5} className={classes.socialIconButtonsContainer}>
        <IconButton
          href="https://www.linkedin.com/company/tdcerhverv/"
          target="_blank"
          rel="noopener"
          className={classes.socialIconButton}
        >
          <SvgIcon component={Linkedin} viewBox="0 0 48 48" />
          <Typography variant="srOnly">TDC Erhverv&apos;s Linkedin</Typography>
        </IconButton>
        <IconButton
          href="https://www.facebook.com/TDCErhvervDK"
          target="_blank"
          rel="noopener"
          className={classes.socialIconButton}
        >
          <SvgIcon component={Facebook} viewBox="0 0 48 48" />
          <Typography variant="srOnly">TDC Erhverv&apos;s Facebook</Typography>
        </IconButton>
        <IconButton
          href="https://www.instagram.com/tdc_erhverv"
          target="_blank"
          rel="noopener"
          className={classes.socialIconButton}
        >
          <SvgIcon component={Instagram} viewBox="0 0 48 48" />
          <Typography variant="srOnly">TDC Erhverv&apos;s Instagram</Typography>
        </IconButton>
      </Box>
    </Grid>
  )
}

export default InformationColumn
