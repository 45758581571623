import React, { useEffect } from 'react'

//Mui Components
import { makeStyles } from '@material-ui/core/styles'
import Grow from '@material-ui/core/Grow'
import Container from '@material-ui/core/Container'

interface IDropdown {
  open: boolean
  navBarHeight: number
  id?: string
  children: JSX.Element | JSX.Element[]
}

const useStyles = makeStyles(({palette, breakpoints}) => ({
  root: {
    backgroundColor: palette.common.white,
    padding: '50px 0',
    borderTop: `1px solid ${palette.grey[300]}`,
    position: 'absolute',
    zIndex: -1,
    width: '100%',
    minHeight: 430,
    [breakpoints.down('sm')]: {
      padding: '40px 0',
      minHeight: ({ navBarHeight }: IDropdown) =>
        `calc(100vh - ${navBarHeight}px)`,
      maxHeight: ({ navBarHeight }: IDropdown) =>
        `calc(100vh - ${navBarHeight}px)`,
      overflowY: 'scroll',
    },
  },
}))

const Dropdown = ({ open, id, children, navBarHeight }: IDropdown) => {
  useEffect(() => {
    const body = document.getElementsByTagName('body')[0]
    if (open) {
      body.style.overflow = 'hidden'
    } else {
      body.style.overflow = ''
    }
  }, [open])

  const classes = useStyles({ navBarHeight, open, children })
  return (
    <Grow in={open} style={{ transformOrigin: 'center top' }}>
      <nav id={id} className={classes.root}>
        <Container>{children}</Container>
      </nav>
    </Grow>
  )
}

export default Dropdown
