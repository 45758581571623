import React, { ReactElement, useEffect } from 'react'
import { MiniBasketButton } from '../MiniBasket/MiniBasketButton'
import { MiniBasket } from '../MiniBasket/MiniBasket'
import { useBasket } from '../../modules/basket'
import { Drawer } from '@material-ui/core'
import { IS_BROWSER } from '../../../constants'

export const MiniBasketWrapper = (): ReactElement => {
  const basket = useBasket()
  const location = IS_BROWSER && window.location

  useEffect(() => {
    basket.closeMiniBasket()
  }, [location.pathname])

  return (
    <>
      {/** Show basket button on all paths except basket, checkout and order confirmation */}
      {/* <Route exact path={routes.basket.route} />
                <Route exact path={routes.checkout.route} />
                <Route exact path={routes.orderConfirmation.route} /> */}
      <Drawer
        anchor="right"
        open={basket.miniBasketOpen}
        onClose={() => basket.closeMiniBasket()}
      >
        <MiniBasket onClose={() => basket.closeMiniBasket()} />
      </Drawer>
      <MiniBasketButton />
    </>
  )
}
