export const searchDataDistributor = data => {
  const searchData = {
    searchLeftImageUrl: '',
    searchRightImageUrl: '',
    searchTopImageUrl: '',
  }
  data?.images?.files.map(item => {
    if (item.displayTitle === 'searchLeftImage') {
      searchData.searchLeftImageUrl = item.image.file.url
    } else if (item.displayTitle === 'searchRightImage') {
      searchData.searchRightImageUrl = item.image.file.url
    } else if (item.displayTitle === 'searchTopImage') {
      searchData.searchTopImageUrl = item.image.file.url
    }
  })
  return searchData
}
