import { useTheme } from '@material-ui/core/styles'
// eslint-disable-next-line no-restricted-imports
import { Breakpoint } from '@material-ui/core/styles/createBreakpoints'
import useMediaQuery from '@material-ui/core/useMediaQuery'



const useMobileDisplay = (size?: Breakpoint) => {
  return useMediaQuery(useTheme().breakpoints.down(size || 'xs'))
}

export default useMobileDisplay
