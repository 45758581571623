import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import SvgIcon from '@material-ui/core/SvgIcon'
import { iconsMap } from '@tdcerhverv/parrotfish/dist/icons/icons'

const useStyles = makeStyles(() => {
  return {
    richTextIcon: {
      height: 48,
      width: 48,
      paddingBottom: 4,
    },
  }
})
export interface IParrotfishIcon {
  height?: number
  width?: number
  iconImage: {
    file: {
      fileName: string
    }
  }
  iconColor?:
    | 'inherit'
    | 'disabled'
    | 'action'
    | 'primary'
    | 'secondary'
    | 'error'
  className?: string
}

const ParrotfishIcon = ({
  iconImage,
  iconColor,
  className,
}: IParrotfishIcon) => {
  const classes = useStyles()

  if (!iconImage?.file?.fileName) {
    return null
  }

  const iconName = iconImage.file.fileName.slice(0, -4)
  const color = iconColor || 'inherit'
  return (
    <SvgIcon
      width="48"
      height="48"
      viewBox="0 0 48 48"
      color={color}
      component={iconsMap[iconName]}
      className={className || classes.richTextIcon}
    />
  )
}

export default ParrotfishIcon
