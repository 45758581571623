import { IconButton, makeStyles, Theme } from '@material-ui/core'
import { TextField } from 'mui-rff'
import React, { ReactElement } from 'react'
import { Form } from 'react-final-form'
import { translate } from '../../helpers/translate'
import { MinusIcon } from '../../icons/generated/MinusIcon'
import { PlusIcon } from '../../icons/generated/PlusIcon'

type Props = {
  min: number
  max?: number
  quantity: number
  onIncrease: () => void
  onDecrease: () => void
  onValueChange: (amount: number) => void
}

type FormData = {
  quantity: undefined | string
}

const useStyles = makeStyles<Theme, Props>(theme => ({
  wrapper: {
    display: 'flex',
  },
  form: {
    width: props => (props.quantity > 99 ? theme.spacing(6) : theme.spacing(5)),
  },
  input: {
    '& input': {
      textAlign: 'center',
      fontSize: '0.875rem',
      paddingTop: '0.5rem',
      paddingBottom: '0.5rem',
      '&[type=number]': {
        '-moz-appearance': 'textfield',
      },
      '&::-webkit-outer-spin-button': {
        '-webkit-appearance': 'none',
      },
      '&::-webkit-inner-spin-button': {
        '-webkit-appearance': 'none',
      },
    },
  },
}))

let formMutators: { setQuantity?: (quantity: number) => void } | undefined

export const QuantityControl = (props: Props): ReactElement => {
  const {
    min,
    max = 1,
    quantity,
    onValueChange,
    onIncrease,
    onDecrease,
  } = props
  const classes = useStyles(props)
  return (
    <div className={classes.wrapper}>
      <IconButton
        aria-label={translate('removeOne')}
        disabled={quantity <= min}
        size="small"
        onClick={() => onDecrease()}
      >
        <MinusIcon fontSize="inherit" />
      </IconButton>
      <Form
        onSubmit={(params: FormData) => {
          let newQuantity = parseInt(String(params.quantity))
          if (isNaN(newQuantity)) {
            formMutators?.setQuantity?.(quantity)
            return
          }
          if (newQuantity < min) {
            newQuantity = min
          }
          if (newQuantity > max) {
            newQuantity = max
          }
          if (newQuantity !== quantity) {
            onValueChange(newQuantity)
          } else {
            formMutators?.setQuantity?.(quantity)
          }
        }}
        initialValues={{ quantity }}
        mutators={{
          setQuantity: (args, state, utils) => {
            utils.changeValue(state, 'quantity', () => args[0])
          },
        }}
        render={({ handleSubmit, form: { mutators } }) => {
          formMutators = mutators
          return (
            <form className={classes.form} noValidate onSubmit={handleSubmit}>
              <TextField
                className={classes.input}
                id="quantity"
                size="small"
                type="number"
                fullWidth
                name="quantity"
                inputProps={{ 'aria-label': 'quantity' }}
                variant="outlined"
                required={true}
                onBlur={handleSubmit}
              />
            </form>
          )
        }}
      />
      <IconButton
        disabled={quantity >= max}
        aria-label={translate('addOne')}
        size="small"
        onClick={() => onIncrease()}
      >
        <PlusIcon fontSize="inherit" />
      </IconButton>
    </div>
  )
}
