/* eslint-disable @typescript-eslint/ban-ts-comment */
import React, {
  useCallback,
  ReactElement,
  useState,
  useEffect,
  useRef,
} from 'react'
import { ISearchData, ISearchStyles } from './Search.types'
import { navigate } from 'gatsby'
import { boxShadow } from '@tdcerhverv/mui-theme/dist/theme/global/shadows'
//Components & Context

//Utils
import {
  autoCompleteRequest,
  quickLinksRequest,
} from '../../../../utils/cludoRequest'
import quickLinkMatch from '../../../../utils/quickLinkMatch'

//MUI
import Grid from '@material-ui/core/Grid'
import SvgIcon from '@material-ui/core/SvgIcon'
import Card from '@material-ui/core/Card'
import Typography from '@material-ui/core/Typography'
import TextField from '@material-ui/core/TextField'
import InputAdornment from '@material-ui/core/InputAdornment'
import { makeStyles, Theme, useTheme } from '@material-ui/core/styles'

import { iconsMap } from '@tdcerhverv/parrotfish/dist/icons/icons'
import useMediaQuery from '@material-ui/core/useMediaQuery'

const maxResults = 10

const useStyles = makeStyles<Theme, ISearchStyles>(theme => ({
  imageTop: {
    marginBottom: 18,
    width: 294,
  },
  searchTitleWrapper: {
    textAlign: 'center',
    marginBottom: 50,
    [theme.breakpoints.down('md')]: {
      marginBottom: 40,
    },
    [theme.breakpoints.down('xs')]: {
      marginBottom: 24,
    },
  },
  searchTitle: {
    [theme.breakpoints.down('xs')]: {
      fontSize: '20px',
    },
  },
  imageTextfieldWrapper: {
    display: 'flex',
  },
  imageLeftWrapper: {
    width: 400,
    margin: '-200px 0',
    [theme.breakpoints.down('md')]: {
      width: 300,
    },
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  imageLeft: {
    width: '100%',
    height: '100%',
  },
  imageRightWrapper: {
    width: 400,
    margin: '-200px 0',
    [theme.breakpoints.down('md')]: {
      width: 300,
    },
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  imageRight: {
    width: '100%',
    height: '100%',
  },
  textFieldWrapper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: 550,
    position: 'relative',
    [theme.breakpoints.down('md')]: {
      width: 500,
    },
    [theme.breakpoints.down('xs')]: {
      width: 294,
    },
  },
  searchTextField: {
    width: '100%',
    '& .MuiInputBase-root': {
      backgroundColor: ({ inverted }) => (inverted ? 'white' : null),
    },
    '& fieldset': {
      border: ({ inverted }) => (inverted ? 'none' : null),
    },
  },
  resultsWrapper: {
    position: 'absolute',
    width: '100%',
    top: ({ size }) => (size === 'small' ? 36 : 44),
    zIndex: 1000,
    padding: '8px 0 8px 0',
    margin: '4px 0 0 0',
    maxHeight: 475,
    boxShadow: boxShadow.medium,
    overflowY: ({ resultsNumber }) =>
      resultsNumber > maxResults ? 'scroll' : 'unset',
    overscrollBehavior: 'contain',
    [theme.breakpoints.down('md')]: {
      top: 90,
    },
    [theme.breakpoints.down('xs')]: {
      top: 72,
    },
  },
  resultLink: {
    width: '100%',
    height: 43,
    display: 'flex',
    alignItems: 'center',
    padding: '0 20px 0 20px',
    borderRadius: 0,
    color: theme.palette.common.black,
    textDecoration: 'none',
    cursor: 'pointer',
    '&:hover': {
      color: theme.palette.common.white,
      backgroundColor: theme.palette.primary.main,
    },
    '&:focus': {
      outline: 'none',
      color: theme.palette.common.white,
      backgroundColor: theme.palette.primary.main,
    },
  },

  resultLinkText: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: ' -webkit-box',
    maxHeight: 43,
    '-webkit-line-clamp': 1,
    '-webkit-box-orient': 'vertical',
  },
}))

const textFieldArgs = {
  type: 'search',
  'aria-labelledby': 'searchTitle',
  InputProps: {
    startAdornment: (
      <InputAdornment position="start">
        <SvgIcon
          fontSize="inherit"
          component={iconsMap['Search']}
          viewBox="0 0 48 48"
        />
      </InputAdornment>
    ),
  },
}

const Search = (props: {
  searchData?: ISearchData
  searchedWord?: string
  className?: string
  basePath?: string
  size?: 'small' | 'medium'
  headline?: string
  inverted?: boolean
  placeholder?: string
  engineId: string
  disableAutofocusOnMobile?: boolean
}): ReactElement => {
  const {
    searchData,
    searchedWord,
    className,
    size,
    headline,
    inverted,
    placeholder,
    engineId,
    basePath,
    disableAutofocusOnMobile,
  } = props
  const ref = useRef(null)
  const textFieldRef = useRef(null)

  const [autoCompResults, setAutoCompResults] = useState([])
  const [typedValue, setTypedValue] = useState(searchedWord)
  const [textFieldValue, setTextFieldValue] = useState(searchedWord)
  const [hoveredLink, setHoveredLink] = useState(-1)
  const [dropDownOpen, setDropDownOpen] = useState(false)
  const isMobile = useMediaQuery(useTheme().breakpoints.down('xs'))
  const classes = useStyles({
    resultsNumber: autoCompResults.length,
    size,
    inverted,
  })

  useEffect(() => {
    if (!disableAutofocusOnMobile && !isMobile) {
      textFieldRef.current?.children[0]?.children[1].focus()
    }
  }, [])

  useEffect(() => {
    setTypedValue(searchedWord)
    setTextFieldValue(searchedWord)
    if (!disableAutofocusOnMobile && !isMobile) {
      textFieldRef.current?.children[0]?.children[1].focus()
    }
  }, [searchedWord])

  useEffect(() => {
    if (autoCompResults.length === 0) {
      setHoveredLink(-1)
    }
  }, [autoCompResults, setHoveredLink])

  useEffect(() => {
    function handleClickOutside(event: Event) {
      if (ref.current && window.innerWidth > 1024) {
        const clickedOutside = !ref.current.contains(event.target)
        if (clickedOutside) {
          setDropDownOpen(false)
        }
      }
    }
    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [ref, setDropDownOpen])

  const onSearchChange = useCallback(
    event => {
      if (!dropDownOpen) {
        setDropDownOpen(true)
      }
      if (hoveredLink === -1) {
        setTypedValue(event.target.value)
      } else {
        setTextFieldValue(event.target.value)
        if (event.target.value === '') {
          setTypedValue('')
        }
      }
      if (event.target.value === '') {
        setTimeout(() => {
          setAutoCompResults([])
        }, 200)
      } else {
        autoCompleteRequest(event.target.value, engineId, setAutoCompResults)
      }
    },
    [hoveredLink, dropDownOpen],
  )

  const onTypedSearchEnter = useCallback(
    event => {
      if (event.keyCode === 13) {
        setDropDownOpen(false)
        const searchedWord = hoveredLink === -1 ? typedValue : textFieldValue
        quicklinksHandler(searchedWord, () => {
          navigate(
            `${basePath || ''}/search-results?cludoquery=${searchedWord}`,
            {
              state: { refurl: window.location.href, refpt: document.title },
            },
          )
          setTextFieldValue(searchedWord)
          textFieldRef.current?.children[0]?.children[1].focus()
        })
      } else if (event.keyCode === 40) {
        event.preventDefault()
        const linksWrapperElement = document.getElementById('resultsWrapper')
        const element = document.getElementById(`${hoveredLink + 1}`)
        if (element) {
          element.focus()
          if (hoveredLink + 1 > 3) {
            linksWrapperElement.scrollTop = (hoveredLink - 2) * 43
          } else {
            linksWrapperElement.scrollTop = 0
          }
          setHoveredLink(hoveredLink + 1)
          setTextFieldValue(element.innerText)
        } else {
          setHoveredLink(-1)
          textFieldRef.current?.children[0]?.children[1].focus()
        }
      } else if (event.keyCode === 38) {
        event.preventDefault()
        const linksWrapperElement = document.getElementById('resultsWrapper')
        const element = document.getElementById(`${hoveredLink - 1}`)
        if (element) {
          element.focus()
          if (hoveredLink > 1) {
            linksWrapperElement.scrollTop = (hoveredLink - 1) * 43
          } else {
            linksWrapperElement.scrollTop = 0
          }
          setTextFieldValue(element.innerText)
          setHoveredLink(hoveredLink - 1)
        } else {
          if (hoveredLink === -1) {
            const lastElement = document.getElementById(
              `${autoCompResults.length - 1}`,
            )
            lastElement.focus()
            setHoveredLink(autoCompResults.length - 1)
          } else {
            textFieldRef.current?.children[0]?.children[1].focus()
            setHoveredLink(hoveredLink - 1)
          }
        }
      }
    },
    [
      setTextFieldValue,
      typedValue,
      setHoveredLink,
      hoveredLink,
      autoCompResults,
      setDropDownOpen,
    ],
  )
  const quicklinksHandler = useCallback((searchedWord, callBack) => {
    quickLinksRequest(searchedWord, engineId, quicklinks => {
      let url = null
      quicklinks.map(quicklink => {
        quicklink.terms.map(term => {
          if (quickLinkMatch(searchedWord, term.name)) {
            url = quicklink.url
            return
          }
        })
      })

      if (url !== null) {
        window.location.replace(url)
      } else {
        callBack()
      }
    })
  }, [])

  const searchTermClick = useCallback(
    item => {
      setDropDownOpen(false)
      quicklinksHandler(item, () => {
        navigate(`${basePath || ''}/search-results?cludoquery=${item}`, {
          state: { refurl: window.location.href, refpt: document.title },
        })
        setTextFieldValue(item)
        textFieldRef.current?.children[0]?.children[1].focus()
      })
    },
    [setDropDownOpen],
  )

  return (
    <div className={className || ''}>
      {isMobile && searchData && (
        <img
          className={classes.imageTop}
          src={searchData?.searchTopImageUrl}
          alt="Search image top"
        ></img>
      )}
      {headline && (
        <Grid item className={classes.searchTitleWrapper}>
          <Typography
            className={classes.searchTitle}
            variant="h4"
            component="h1"
            id="searchTitle"
          >
            {headline}
          </Typography>
        </Grid>
      )}
      <Grid item className={classes.imageTextfieldWrapper}>
        {searchData && (
          <div className={classes.imageLeftWrapper}>
            <img
              className={classes.imageLeft}
              src={searchData?.searchLeftImageUrl}
              alt="Search image left"
            />
          </div>
        )}
        <Grid
          item
          className={classes.textFieldWrapper}
          onKeyDown={event => onTypedSearchEnter(event)}
        >
          <TextField
            {...textFieldArgs}
            className={classes.searchTextField}
            onChange={onSearchChange}
            value={hoveredLink === -1 ? typedValue : textFieldValue}
            id="searchTextField"
            ref={textFieldRef}
            onClick={() => setDropDownOpen(true)}
            autoComplete="off"
            size={size ? size : 'medium'}
            placeholder={placeholder ? placeholder : 'Søg'}
          />
          {autoCompResults.length > 0 && dropDownOpen && (
            <Card
              className={classes.resultsWrapper}
              id="resultsWrapper"
              ref={ref}
            >
              {autoCompResults.map((item, index) => {
                return (
                  <div
                    key={index}
                    className={classes.resultLink}
                    onClick={() => searchTermClick(item)}
                    tabIndex={0}
                    onMouseEnter={() => setHoveredLink(index)}
                    id={`${index}`}
                  >
                    <Typography className={classes.resultLinkText}>
                      {item}
                    </Typography>
                  </div>
                )
              })}
            </Card>
          )}
        </Grid>
        {searchData && (
          <div className={classes.imageRightWrapper}>
            <img
              className={classes.imageRight}
              src={searchData?.searchRightImageUrl}
              alt="Search image right"
            />
          </div>
        )}
      </Grid>
    </div>
  )
}

export default Search
