const quickLinkMatch = (searchWord: string, quickTerm: string) => {
  const searchTerm = searchWord.toLowerCase()
  const quickLinkTerm = quickTerm.toLowerCase()
  let slicedTerm = ''
  if (quickLinkTerm.startsWith('*') && quickLinkTerm.endsWith('*')) {
    slicedTerm = quickLinkTerm.slice(1, -1)
    return searchTerm.includes(slicedTerm)
  } else if (quickLinkTerm.startsWith('*')) {
    slicedTerm = quickLinkTerm.slice(1)
    return searchTerm.endsWith(slicedTerm)
  } else if (quickLinkTerm.endsWith('*')) {
    slicedTerm = quickLinkTerm.slice(0, -1)
    return searchTerm.startsWith(slicedTerm)
  }
  return searchTerm == quickLinkTerm
}

export default quickLinkMatch
