import React, { PropsWithChildren } from 'react';
import ErrorIcon from '../../icons/Error';
import { makeStyles, Typography } from '@material-ui/core';
import { red } from '@material-ui/core/colors';

type IErrorMessageProps = {
    title?: string;
    message?: string | React.ReactNode;
};

const useStyles = makeStyles((theme) => ({
    root: {
        color: theme.palette.error.dark,
        borderBottom: '2px solid currentColor',
        background: red['50'],
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
        paddingTop: theme.spacing(1.5),
        paddingBottom: theme.spacing(1.5),
        display: 'flex',

        '&:first-child': {
            marginTop: 0,
        },

        '&:last-child': {
            marginBottom: 0,
        },
    },
    icon: {
        marginRight: '.5em',
        fontSize: '1.2em',
        verticalAlign: 'middle',
        flex: '0 0 40px',
        minWidth: 40,
    },
    text: {
        flex: '1 1 auto',
        paddingRight: theme.spacing(5),

        '& a': {
            color: 'inherit',
            textDecoration: 'underline',
        },
    },
}));

const ErrorMessage: React.FC<IErrorMessageProps> = React.memo(
    (props: PropsWithChildren<IErrorMessageProps>): JSX.Element => {
        const { message, title, children } = props;
        const classes = useStyles();

        return (
            <Typography className={classes.root} component="aside" variant="body2">
                <ErrorIcon className={classes.icon} />
                {(title || message) && (
                    <div className={classes.text}>
                        {title && <Typography variant="subtitle2">{title}</Typography>}
                        {typeof message === 'string' ? <span dangerouslySetInnerHTML={{ __html: message }} /> : message}
                    </div>
                )}
                {children}
            </Typography>
        );
    },
);

ErrorMessage.displayName = 'ErrorMessage';

export { ErrorMessage };
