import React, { ReactElement } from 'react'

// MUI
import { makeStyles } from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import SvgIcon from '@material-ui/core/SvgIcon'

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
//@ts-ignore
import Logo from '@tdcerhverv/parrotfish/dist/icons/Icons/tdc_simple_logo.svg'

const useStyles = makeStyles((theme) => ({
    root: {
        marginBottom: 16,
        color: theme.palette.primary.main,
    },
    logo: {
        width: 32,
        height: 32,
    },
}))

const LogoColumn = (): ReactElement => {
    const classes = useStyles()
    return (
        <Grid spacing={4} justifyContent="center" container className={classes.root}>
            <Grid item xs={12} md={12} lg={10}>
                <Box>
                    <SvgIcon className={classes.logo} component={Logo} viewBox="0 0 48 48" />
                </Box>
            </Grid>
        </Grid>
    )
}

export default LogoColumn
