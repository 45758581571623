import { Basket, LocalizedBasket } from '../../../types/generated/api.interface';
import { getPublicShopAPIUrl } from '../../../helpers/api-url';
import { CancellableRequest, requests } from '../../../service/requests';
import { AxiosResponse } from 'axios';

export const putBasket = (data: Basket): CancellableRequest<AxiosResponse<LocalizedBasket>> =>
    requests.cancellablePut<LocalizedBasket>(`${getPublicShopAPIUrl()}basket`, data);

export const getBasket = async (basketId?: string): Promise<LocalizedBasket> => {
    const params = basketId ? { basketId } : undefined;
    const { data } = await requests.get<LocalizedBasket>(`${getPublicShopAPIUrl()}basket`, { params });
    return data;
};
