import { updateEntry } from '../helpers/update-entry';
import { Basket } from '../model/basket-model';

export const addToLines = (
    basketLines: Basket['lines'] = [],
    productId: string,
    amountToAdd: number,
): Basket['lines'] => {
    const currentProductIndex = basketLines.findIndex((line) => line.variantId === productId);
    if (currentProductIndex !== -1) {
        const currentLine = basketLines[currentProductIndex];
        const newAmount = (currentLine.amount || 0) + amountToAdd;
        return updateEntry(
            basketLines,
            {
                amount: newAmount,
            },
            currentProductIndex,
        );
    } else {
        return updateEntry(
            basketLines,
            {
                variantId: productId,
                amount: amountToAdd,
            },
            basketLines.length,
        );
    }
};
