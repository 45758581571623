import { IS_BROWSER } from '../../../../constants';
import { getLocalStorageItem, setLocalStorageItem } from '../../../helpers/storage.helper';
import { LocalizedBasket } from '../../../types/generated/api.interface';

const basketStorageKey = 'tdc-shop-basket';

export const getBasketFromStorage = (): LocalizedBasket | undefined => {
    const storageBasket = getLocalStorageItem(basketStorageKey);
    if (storageBasket) {
        return JSON.parse(storageBasket);
    }
};

export const setBasketToStorage = (basket: LocalizedBasket): void => {
    setLocalStorageItem(basketStorageKey, JSON.stringify(basket));
};

/** Subscribe to events of other tabs/windows writing a newer basket to local storage */
/** No unsubscribe logic */
export const subscribeToExternalChanges = (callback: (newBasket: LocalizedBasket) => void): void => {
    if (!IS_BROWSER) {
        return;
    }
    window.addEventListener('storage', (storageEvent) => {
        if (storageEvent.key === basketStorageKey) {
            const newValue: LocalizedBasket = storageEvent.newValue ? JSON.parse(storageEvent.newValue) : {};
            callback(newValue);
        }
    });
};
