/* eslint-disable mui-unused-classes/unused-classes */
/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React, { useEffect } from 'react'
import { useStaticQuery, graphql } from 'gatsby'

// Components & Context
import AlertBanner from '../AlertBanner'
import Footer from '../Footer'
import Navigation from '../Navigation'
import Box from '@material-ui/core/Box'
import { makeStyles } from '@material-ui/core/styles'
import { usePageProvider } from '../../context/PageProvider'
import { ConditionalWrapper } from '../../utils'
import HeaderScript from '../HeaderScript'
import { ILayout } from './types'
import Link from '@material-ui/core/Link'
import Divider from '@material-ui/core/Divider'
import { Link as GatsbyLink } from 'gatsby'
import { UsabillaFeedback } from '../UsabillaFeedback/UsabillaFeedback'
import { GETFEEDBACK_THUMBS_RATING, IS_LOCALHOST } from '../../../constants'
import useMobileDisplay from '../../hooks/useMobileDisplay'
import {
  injectFeedbackButtonScript,
  toggleFeedbackButtonVisibility,
} from '../../utils/handleFeedbackButtonScript'
import { useConsent } from '../../hooks/useConsent'
import { useScrollToElement } from '../../hooks/useScrollToElement'
import { usePageViewAnalytics } from '../../hooks/useAnalytics'
import { useChatWidget } from '../../hooks/useChatWidget'
const useStyles = makeStyles(({ breakpoints, palette }) => ({
  // eslint-disable-next-line mui-unused-classes/unused-classes
  '@global': {
    html: {
      width: '100vw',
      scrollBehavior: 'smooth',
    },
    body: {
      overflowX: 'hidden',
      paddingRight: '0px !important',
      width: '100%',
      height: '100%',
      '& #gatsby-focus-wrapper': {
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100vh',
      },
    },
  },
  main: {
    flexGrow: 1,
  },
  alertBannerWrapper: {
    '& .MuiAlert-root:nth-child(even)': {
      borderTop: `solid 1px ${palette.common.black}`,
    },
  },
  enterpriseBar: {
    height: 40,
    width: '100%',
    padding: '5px 0',
    backgroundColor: palette.grey[300],
    [breakpoints.down('sm')]: {
      justifyContent: 'center',
      display: 'flex',
    },
  },
  linksWrapper: {
    maxWidth: 1420,
    display: 'flex',
    margin: 'auto',
    boxSizing: 'border-box',
    alignItems: 'center',
    padding: '0 24px',
    height: '100%',
    '& a': {
      textDecoration: 'none',
      fontWeight: 'bold',
      fontSize: 14,
      '&:hover': {
        color: palette.primary.main,
      },
    },
    '& hr': {
      height: '50%',
      alignSelf: 'auto',
      backgroundColor: palette.common.black,
    },
  },
  ordinaryLink: {
    color: palette.common.black,
  },
  activeLink: {
    color: palette.primary.main,
    borderBottom: `0.5px solid ${palette.primary.main}`,
  },
}))

const Layout: React.FC<ILayout> = ({
  Hero,
  children,
  className,
  pageVariant = 'DEFAULT',
  showSplitMenu = false,
  usabilla = true,
  usabillaLargePadding = false,
  hasFeedbackButton = false,
  trackingPageInfo = null,
}) => {
  const data = useStaticQuery(graphql`
    query LayoutElements {
      siteSettings: contentfulSiteSettings {
        globalAlert {
          ...alertBannerFragment
        }
        genesys
      }
    }
  `)
  // Adobe Analytics - Global Variables
  const pageInfo = { ...trackingPageInfo, pageVariant }
  usePageViewAnalytics({ trackingPageInfo: pageInfo })
  const { siteSettings } = data
  const { bannerExcluded, alertBanner } = usePageProvider()

  const { cookie_cat_statistic } = useConsent()
  useScrollToElement()
  const classes = useStyles()

  const isMobile = useMobileDisplay('sm')
  {
    siteSettings.genesys.includes('All Pages') &&
      useChatWidget({ hasGenesysMessenger: true })
  }
  const showFeedbackButton =
    (cookie_cat_statistic && hasFeedbackButton) ||
    (IS_LOCALHOST && hasFeedbackButton)

  useEffect(() => {
    showFeedbackButton && injectFeedbackButtonScript()
    toggleFeedbackButtonVisibility(showFeedbackButton)
  }, [])
  return (
    <>
      <HeaderScript />
      <ConditionalWrapper
        condition={siteSettings.globalAlert}
        wrapper={children => (
          <div className={classes.alertBannerWrapper}>{children}</div>
        )}
      >
        {!bannerExcluded && siteSettings.globalAlert && (
          <AlertBanner {...siteSettings.globalAlert} />
        )}
        {!bannerExcluded && <AlertBanner {...alertBanner} />}
      </ConditionalWrapper>
      {showSplitMenu && (
        <div id="split-menu" className={classes.enterpriseBar}>
          <div className={classes.linksWrapper}>
            <Link
              component={GatsbyLink}
              to="/smb"
              className={
                pageVariant == 'SMB' ? classes.activeLink : classes.ordinaryLink
              }
            >
              {isMobile ? 'SMV' : 'Små og mellemstore virksomheder'}
            </Link>
            <Divider orientation="vertical" variant="middle" flexItem />
            <Link
              component={GatsbyLink}
              to="/enterprise"
              className={
                pageVariant === 'ENTERPRISE' || pageVariant === null
                  ? classes.activeLink
                  : classes.ordinaryLink
              }
            >
              {isMobile
                ? 'Enterprise'
                : 'Større virksomheder og offentlige institutioner'}
            </Link>
          </div>
        </div>
      )}
      <Navigation pageVariant={pageVariant} />
      {!!Hero && <Hero />}
      <Box
        id="main"
        className={`${classes.main} ${className || ''}`}
        component="main"
      >
        {children}
      </Box>
      {usabilla && cookie_cat_statistic && (
        <Box
          component="section"
          {...(usabillaLargePadding ? { py: 8 } : { pb: 8 })}
        >
          <UsabillaFeedback
            widgetId="b29dc9281420"
            containerId={GETFEEDBACK_THUMBS_RATING}
          />
        </Box>
      )}
      <Footer />
    </>
  )
}

export default Layout
