import { makeStyles, Typography } from '@material-ui/core';
import React, { FunctionComponent, ReactElement } from 'react';
import { ErrorFilledIcon } from '../../icons/generated/ErrorFilledIcon';
import { SuccessFilledIcon } from '../../icons/generated/SuccessFilledIcon';
import { WarningFilledIcon } from '../../icons/generated/WarningFilledIcon';
import { TDCIconProps } from '../../icons/TDCIcon';
import { StockStatus as APIStockStatus } from '../../types/generated/api.interface';
import { StockStatus as SCStockStatus } from '../../types/generated/sitecore.interface';

type Props = { color?: APIStockStatus | SCStockStatus['Status']; text?: string };

const useStyles = makeStyles((theme) => ({
    wrapper: {
        display: 'flex',
        alignItems: 'center',
    },
    icon: {
        fontSize: '0.75rem',
        marginRight: theme.spacing(1),
        color: (props: Props) => {
            switch (props.color) {
                case 'green':
                case 'Green':
                    return theme.palette.success.main;
                case 'yellow':
                case 'Yellow':
                    return theme.palette.warning.main;
                case 'red':
                case 'Red':
                default:
                    return theme.palette.error.main;
            }
        },
    },
}));

export const StockStatusIndicator = (props: Props): ReactElement => {
    const { color, text } = props;
    const styles = useStyles(props);
    let Icon: FunctionComponent<TDCIconProps>;
    switch (color) {
        case 'green':
        case 'Green':
            Icon = SuccessFilledIcon;
            break;
        case 'yellow':
        case 'Yellow':
            Icon = WarningFilledIcon;
            break;
        case 'Red':
        case 'red':
        default:
            Icon = ErrorFilledIcon;
            break;
    }
    return (
        <div className={styles.wrapper}>
            <Icon className={styles.icon} />
            <Typography variant="caption" color="textSecondary" component="div">
                {text}
            </Typography>
        </div>
    );
};
