import { useEffect } from 'react'
import trackingEvent, {
  FormattedPageInfo,
  OrderInfo,
  PageInfo,
  ProductsInfo,
  SearchInfo,
} from '../utils/trackingEvent'
import { useLocation } from '@reach/router'

type PageAnalytics = {
  trackingPageInfo: PageInfo
  trackingProductsInfo?: ProductsInfo
  trackingOrderInfo?: OrderInfo
  trackingSearchInfo?: SearchInfo
  dependency?: Array<string>
}

type Validate<T> = (data: T) => boolean
const validatePageInfo: Validate<PageInfo> = pageInfo => {
  const isValid: boolean =
    !!pageInfo.pageName && !!pageInfo.siteSection && !!pageInfo.subSection1
  return isValid
}

/**
 * Format page info from contentful for Adobe analyics.
 *
 * example input:
 * {
 *   pageName: 'test'
 *   siteSection :'foo'
 *   subSection1: 'bar'
 *   subsection2: 'baz'
 * }
 *
 * expected output
 *  {
 *   pageName: 'foo > bar > baz > test'
 *   siteSection :'foo'
 *   subSection1: 'foo > bar'
 *   subsection2: 'foo > bar > baz'
 *   subsection3: 'foo > bar > baz'
 * }
 *
 */

const formatPageInfo = (
  pageInfo: PageInfo,
  fullURL: string,
  urlWithoutQuery: string,
) => {
  const {
    pageName,
    siteSection,
    subSection1,
    subSection2,
    subSection3,
    isSmbMenu,
    pageType,
    id,
  } = pageInfo

  const isSmb = isSmbMenu ? `smb` : 'enterprise'

  const formattedSubSection1 =
    siteSection === subSection1
      ? siteSection
      : `${siteSection} > ${subSection1}`

  const formattedSubSection2 =
    subSection1 === subSection2
      ? formattedSubSection1
      : `${formattedSubSection1}${subSection2 ? ` > ${subSection2}` : ''}`

  const formattedSubSection3 =
    subSection2 === subSection3
      ? formattedSubSection2
      : `${formattedSubSection2}${subSection3 ? ` > ${subSection3}` : ''}`

  const formattedPageName =
    pageName === formattedSubSection3
      ? `${pageName}`
      : `${formattedSubSection3} > ${pageName}`

  const formattedPageInfo: FormattedPageInfo = {
    pageName: formattedPageName,
    adformPageName: formattedPageName,
    divisionSection: isSmb,
    siteSection,
    subSection1: formattedSubSection1,
    subSection2: formattedSubSection2,
    subSection3: formattedSubSection3,
    pageType: pageType,
    id,
    fullURL,
    urlWithoutQuery,
  }
  return formattedPageInfo
}

// Page view
export const usePageViewAnalytics = (data: PageAnalytics) => {
  const {
    trackingPageInfo,
    trackingProductsInfo,
    trackingOrderInfo,
    trackingSearchInfo,
    dependency,
  } = data
  const { origin, pathname, href } = useLocation()
  const fullURL = href
  const urlWithoutQuery = origin + pathname

  useEffect(
    () => {
      if (!trackingPageInfo) return
      if (!validatePageInfo(trackingPageInfo)) return

      const formattedPageInfo = formatPageInfo(
        trackingPageInfo,
        fullURL,
        urlWithoutQuery,
      )
      trackingEvent({
        event: 'page_view',
        pageInfo: formattedPageInfo,
        products: trackingProductsInfo,
        orderInfo: trackingOrderInfo,
        searchInfo : trackingSearchInfo
      })
    },
    dependency ? dependency : [],
  )
}
