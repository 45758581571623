/**
 * This component adds scripts to the <head> section of the HTML
 */
import React from 'react'
import { Helmet } from 'react-helmet'
import { IS_PROD } from '../../../constants'

const HeaderScripts = () => {
  return (
    <Helmet>
      {/* Cookie Information Pop-up Script */}
      <script
        id="CookieConsent"
        src="https://policy.app.cookieinformation.com/uc.js"
        data-culture="DA"
        type="text/javascript"
        data-gcm-enabled="true"
        crossOrigin="anonymous"
      ></script>

      {/* Adobe Analytics */}
      <script
        src={`https://assets.adobedtm.com/0925f9fe4554/dfa5fa999a23/launch-${
          IS_PROD ? '52a09d2a030a' : 'c917265d5f8b-development'
        }.min.js`}
        async
      ></script>
    </Helmet>
  )
}
export default HeaderScripts
