import create from 'zustand';
import { RootStyles, RootStylesActions } from '../model/root-styles-model';

export const useRootStyles = create<RootStyles>((setStyles, getStyles) => {
    const actions: RootStylesActions = {
        changeBackgroundColor: (backgroundColor) => {
            if (backgroundColor !== getStyles().backgroundColor) {
                setStyles({ backgroundColor });
            }
        },
    };

    return { ...actions, backgroundColor: 'default' };
});
