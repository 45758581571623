// TODO: Investigator Gatsby query string pugin as replacement:
// https://www.gatsbyjs.com/plugins/gatsby-plugin-use-query-params/

import queryString from 'query-string';
import { IS_BROWSER } from '../../constants';

export const sanitizeAndEncodeArgument = (argument: string): string =>
    encodeURIComponent(
        argument
            .trim()
            .toLowerCase()
            .replace(/[^a-z0-9_-]/gi, '-'),
    );

export const addParameterToSerializedURL = (path: string, dataToAdd: string): string =>
    `${path}${path.indexOf('?') >= 0 ? '&' : '?'}${
        dataToAdd[0] === '&' || dataToAdd[0] === '?' ? dataToAdd.substr(1) : dataToAdd
    }`;

/*
 * NotFound image has moved to Contentful (don't know where it was before), which expects ?w and ?h instead of ?width and ?height
 * I'm not sure if this is applicable to all images, but it seems to work...
*/
export const addWidthToImagePath = (path = '', width = 0): string =>
    width && path.indexOf('height=') < 0
        ? addParameterToSerializedURL(path.replace(/&?w=[0-9]+/, ``), `w=${width}`)
        : path;
export const addHeightToImagePath = (path = '', height = 0): string =>
    height ? addParameterToSerializedURL(path.replace(/&?h=[0-9]+/, ``), `h=${height}`) : path;

export const getParametersFromQueryString = (
    query: string = IS_BROWSER && window.location.toString(),
): { [key: string]: string | string[] | null } => {
    return queryString.parse(query);
};
