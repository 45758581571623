import { makeStyles, Typography } from '@material-ui/core'
import React, { ReactElement } from 'react'
import { translate } from '../../helpers/translate'

const useStyles = makeStyles(({ spacing }) => ({
  table: {
    borderCollapse: 'separate',
    borderSpacing: `0 ${spacing(0.5)}px`,
    width: '100%',
    '& th': {
      textAlign: 'left',
    },
    '& td': {
      textAlign: 'right',
    },
  },
}))

type BasketPriceTableProps = {
  subTotal?: string
  freight?: string
  vat?: string
  totalInclVat?: string
}

export const PriceTableRender = ({
  freight,
  subTotal,
  totalInclVat,
  vat,
}: BasketPriceTableProps): ReactElement => {
  const classes = useStyles()
  return (
    <table className={classes.table}>
      <tr>
        <Typography component="th" variant="body1">
          {translate('basket.priceTable.totalExclVat')}
        </Typography>
        <Typography component="td" variant="body1">
          {subTotal}
        </Typography>
      </tr>
      <tr>
        <Typography component="th" variant="body1">
          {translate('basket.priceTable.freight')}
        </Typography>
        <Typography component="td" variant="body1">
          {freight}
        </Typography>
      </tr>
      <tr>
        <Typography component="th" variant="body1">
          {translate('basket.priceTable.vat')}
        </Typography>
        <Typography component="td" variant="body1">
          {vat}
        </Typography>
      </tr>
      <tr>
        <Typography component="th" variant="subtitle1">
          {translate('basket.priceTable.totalInclVat')}
        </Typography>
        <Typography component="td" variant="subtitle1">
          {totalInclVat}
        </Typography>
      </tr>
    </table>
  )
}
