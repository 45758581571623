import { IS_BROWSER, USER_PREFERS_REDUCED_MOTION } from '../../constants'

type IScrollPosition = {
  x: number
  y: number
}

export const getScrollPosition = (): IScrollPosition => {
  if (!IS_BROWSER)
    return {
      x: 0,
      y: 0,
    }
  return {
    x: window.pageXOffset || document.documentElement.scrollLeft,
    y: window.pageYOffset || document.documentElement.scrollTop,
  }
}

export const getElementYPosition = (element?: HTMLElement): number => {
  if (element) {
    const { top: elementYPosition } = element.getBoundingClientRect()
    const { y: currentYScroll } = getScrollPosition()
    const offset = 120

    return Math.round(currentYScroll + elementYPosition - offset)
  }

  return 0
}

export const scrollToYPosition = (
  yPosition: number,
  disableSmooth = false,
): void => {
  if (IS_BROWSER) {
    if (disableSmooth || USER_PREFERS_REDUCED_MOTION) {
      window.scrollTo({ top: yPosition })
    } else {
      window.scrollTo({ top: yPosition, behavior: 'smooth' })
    }
  }
}
