import { useState, useEffect, useCallback } from 'react'
import { HAS_COOKIESOLUTION } from '../../constants'

declare global {
  interface Window {
    cicl: string[]
    CookieConsent: {
      renew: () => void
    }
    CookieInformation: {
      loadConsent: () => void
      getConsentGivenFor: (cookieCategory: string) => boolean
      submitAllCategories: () => void
    }
  }
}

interface State {
  cookie_cat_functional: boolean
  cookie_cat_statistic: boolean
  cookie_cat_marketing: boolean
}

const initialState = {
  cookie_cat_functional: false,
  cookie_cat_statistic: false,
  cookie_cat_marketing: false,
}

export const useConsent = () => {
  const [consentState, setConsentState] = useState<State>(initialState)

  const handleSettingConsent = useCallback(() => {
    window?.cicl?.forEach(category => {
      setConsentState(state => ({
        ...state,
        [category]: window?.CookieInformation?.getConsentGivenFor(category),
      }))
    })
  }, [consentState])

  useEffect(() => {
    if (!HAS_COOKIESOLUTION) return
    window.addEventListener(
      'CookieInformationConsentGiven',
      handleSettingConsent,
    )

    return () =>
      window.removeEventListener(
        'CookieInformationConsentGiven',
        handleSettingConsent,
      )
  }, [handleSettingConsent, consentState])

  useEffect(() => {
    if (!HAS_COOKIESOLUTION) return
    window?.CookieInformation?.loadConsent()
  }, [])

  return consentState
}
