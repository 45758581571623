import {
  /* getSessionStorageItem, */ removeSessionStorageItem,
  setSessionStorageItem,
} from "./storage.helper";

const keyPrefix = "tdc-feature-toggle|";

type Features = {
  basket: boolean;
};

export const featureKeys = {
  basket: `${keyPrefix}basket`,
};

let features: Features;

export const enableFeature = (key: string): void => {
  setSessionStorageItem(featureKeys[key], "1");
  window.location.reload();
};

export const disableFeature = (key: string): void => {
  removeSessionStorageItem(featureKeys[key]);
  window.location.reload();
};

/**
 * Should only be used in development/testing
 * To release to the end-user you must clean up your feature toggle
 */
export const useFeatures = (): Features => {
  if (!features) {
    features = {
      basket: true, //!!getSessionStorageItem(featureKeys.basket),
    };
  }
  return features;
};
