/*
 * IS_STAGING and IS_DEV might be redundant...
 * They have been included to clarify the logic distinguishing environments
 * And in case they may be useful going forward
 */

// is TRUE on shop.tdc.dk, main--tdcdk-shop.netlify.app/
export const IS_PROD: string = process.env.GATSBY_SHOP_PROD_ENV

// is TRUE when running gatsby develop
export const IS_DEV: boolean = process.env.NODE_ENV === 'development'

// is TRUE if its localhost
export const IS_LOCALHOST: boolean = !IS_PROD && IS_DEV

// is TRUE when window is defined
export const IS_BROWSER: boolean = typeof window !== 'undefined' // Use this on SSR

// is TRUE when document is defined
export const IS_DOCUMENT: boolean = typeof document !== 'undefined'

// Check for CookieInformation Script
export const HAS_COOKIESOLUTION: boolean =
  IS_BROWSER && typeof window.CookieInformation !== 'undefined'

// CLUDO
export const CLUDO_API_KEY = '11ec4acd5dc54c6fab959b26bd2a3fdf'
export const CLUDO_CUSTOMER_ID = '2060'
export const CLUDO_ENGINE_ID = '10059'
export const CLUDO_ENGINE_ID_KUNDESERVICE = '10075'
export const CLUDO_HOST = 'https://api-eu1.cludo.com/api/v3'

export enum FormType {
  CASE = 'CASE',
  LEAD = 'LEAD',
}

// SIMPLE EMAIL SERVICE
export const SIMPLE_EMAIL_SERVICE_ENDPOINT =
  'https://erhverv-simple-email.public.lqd.dk'

// id's used for the getFeedback widget
export const GETFEEDBACK_FLOW_RATING = '5d02192fefaab73dad32cbfd'
export const GETFEEDBACK_THUMBS_RATING = '5c73d5c101634d701d3482fd'

export const ERHVERVSCENTER_API_DEV = 'https://tdce-sc.godkend.dk/_api/'
export const ERHVERVSCENTER_API_PROD = 'https://sc.tdcerhvervscenter.dk/_api/'
export const PUBLIC_SHOP_API_PROD = 'https://tdc-api.tdcerhvervscenter.dk/'
export const PUBLIC_SHOP_API_DEV = 'https://tdc-api.godkend.dk/'
export const PRODUCT_LIST_ENDPOINT = 'publicproducts'
export const SEARCH_SUGGESTION_ENDPOINT = 'publicproducts/suggestions/search'
export const PRODUCT_DETAILS_ENDPOINT = 'publicproducts/'

export const ABORT_CONTROLLER_SUPPORTED =
  IS_BROWSER && 'AbortController' in window

export const ITEM_LIMIT_IN_FILTER_GROUPS = 5

export const TDCE_LINK_DESTINATION = 'https://www.tdcerhvervscenter.dk/log-ind'

export const MAIL_SERVICE_URL = 'https://erhverv-simple-email.public.lqd.dk/'
export const MAIL_SERVICE_TOKEN_ENDPOINT = 'token'
export const MAIL_SERVICE_SEND_ENDPOINT = 'send'

export const MAIL_CONTACT_FORM_ID = '113b80a3-0881-4c60-bb26-c1bffd9c2972'
export const MAIL_CONTACT_FORM_PROVIDER = 'tdcdkShopContact'

export const USER_PREFERS_REDUCED_MOTION =
  IS_BROWSER &&
  window.matchMedia &&
  window.matchMedia('(prefers-reduced-motion)').matches
