import React, { ReactElement, useEffect } from 'react'
import { routes } from '../Router/routes'
import { scrollToYPosition } from '../../helpers/scroll'
import { IS_BROWSER } from '../../../constants'

export const ScrollPositionHandler = (): ReactElement => {
  const location = IS_BROWSER && window.location

  useEffect((): void => {
    if (location?.pathname === routes.home.route) {
      return
    }
    scrollToYPosition(0)
  }, [location?.pathname])

  return <></>
}
