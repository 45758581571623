import { iconsMap } from '@tdcerhverv/parrotfish/dist/icons/icons'

export const LoginMenuItems = [
  {
    title: 'Selvbetjening Erhverv',
    description: 'Til administratoren',
    url: 'https://selvbetjening.tdc.dk',
    icon: iconsMap['Selfservice'],
  },
  {
    title: 'Mit TDC Erhverv',
    description: 'Til medarbejderen',
    url: 'http://mit.tdcerhverv.dk/',
    icon: iconsMap['User_phone'],
  },
  {
    title: 'Indkøbsportal',
    description: 'Til erhvervskunder',
    url: 'https://www.tdcerhvervscenter.dk/log-ind',
    icon: iconsMap['Shopping_cart'],
  },
  /*{
    title: 'Skype for business Selvbetjening',
    url: 'https://selvbetjening.sky.tdc.dk/auth/',
    icon: iconsMap['Skype'],
  },
  {
    title: 'Teams Selvbetjening',
    url: 'https://app.sky.tdc.dk/',
    icon: iconsMap['Teams'],
  }*/
]
