import { IconButton, makeStyles, Snackbar, Typography } from '@material-ui/core'
import React, { ReactElement } from 'react'
import { ErrorMessage } from '../ErrorMessage'
import { translate } from '../../helpers/translate'
import CloseIcon from '../../icons/generated/CloseIcon'
import { useNotifications } from '../../modules/notifications'

const useStyles = makeStyles(theme => {
  return {
    wrapper: {
      '> * & *': {
        marginBottom: theme.spacing(2),
      },
    },
    contents: {
      display: 'flex',
      maxWidth: theme.spacing(40),
    },
    closeBtn: {
      margin: theme.spacing(1),
    },
  }
})

export const NotificationMessages = (): ReactElement => {
  const classes = useStyles()
  const { current, closeNotification } = useNotifications()
  const { message } = current || {}

  return (
    <>
      {!!message && (
        <Snackbar
          open={true}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          autoHideDuration={10000}
          onClose={(_event?: React.SyntheticEvent, reason?: string) => {
            if (reason === 'clickaway') {
              return
            }
            closeNotification()
          }}
        >
          <ErrorMessage>
            <div className={classes.contents}>
              <Typography variant="subtitle2" component="span">
                {message}
              </Typography>
              <IconButton
                className={classes.closeBtn}
                size="small"
                aria-label={translate('close')}
                color="inherit"
                onClick={() => {
                  closeNotification()
                }}
              >
                <CloseIcon fontSize="small" />
              </IconButton>
            </div>
          </ErrorMessage>
        </Snackbar>
      )}
    </>
  )
}
