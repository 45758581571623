import { CLUDO_CUSTOMER_ID, CLUDO_HOST } from '../../constants'
import { ICludoRequest, ICludoTracking } from './types'

const baseUrl = `${CLUDO_HOST}/${CLUDO_CUSTOMER_ID}`

const getSearchRequestsOptions = (engineId: string) => {
  //https://docs.cludo.com/#:~:text=with%20basic%20authentication.-,Header,-%24%20curl%20%22https%3A//apis
  const authorization = `SiteKey ${btoa(
    `${CLUDO_CUSTOMER_ID}:${engineId}:SearchKey`,
  )}`
  return {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: authorization,
    },
  }
}

export const autoCompleteRequest = (searchValue, engineId, callBack) => {
  const url = `${baseUrl}/${engineId}/autocomplete?text=${searchValue}`

  fetch(url, getSearchRequestsOptions(engineId))
    .then(res => res.json())
    .then(data => (Array.isArray(data) ? callBack(data) : callBack([])))
    .catch(() => callBack([]))
}

export const quickLinksRequest = (searchValue, engineId, callBack) => {
  const url = `${baseUrl}/${engineId}/websites/publicsettings`

  fetch(url)
    .then(res => res.json())
    .then(data => callBack(data.quicklinks))
    .catch(() => callBack([]))
}

const fetchCludoSearchResults = (
  engineId: string,
  callBack: (data) => void,
  requestBody: ICludoRequest,
) => {
  fetch(`${baseUrl}/${engineId}/search`, {
    ...getSearchRequestsOptions(engineId),
    method: 'POST',
    body: JSON.stringify(requestBody),
  })
    .then(res => res.json())
    .then(data => {
      callBack(data)
    })
}

export const fetchCludoTracking = (
  engineId: string,
  callBack: (data) => void,
  requestBody: ICludoTracking,
) => {
  fetch(`${baseUrl}/${engineId}/search/pushstat/querylog`, {
    ...getSearchRequestsOptions(engineId),
    method: 'POST',
    body: JSON.stringify(requestBody),
  })
    .then(res => res.json())
    .then(data => {
      callBack(data)
    })
}

export const fetchCludoClickingTrack = (
  engineId: string,
  callBack: (data) => void,
  requestBody: ICludoTracking,
) => {
  fetch(`${baseUrl}/${engineId}/search/pushstat/clicklog`, {
    ...getSearchRequestsOptions(engineId),
    method: 'POST',
    body: JSON.stringify(requestBody),
  })
    .then(res => res.json())
    .then(data => {
      callBack(data)
    })
}

export const fullSearchRequest = (
  searchValue: string,
  searchPage: number,
  engineId: string,
  callBack: (data) => void,
) => {
  fetchCludoSearchResults(engineId, callBack, {
    page: searchPage,
    perPage: 10,
    query: searchValue,
  })
}

export const filterSearchRequest = (
  searchValue: string,
  searchPage: number,
  filterTag: string,
  engineId: string,
  callBack: (data) => void,
) => {
  fetchCludoSearchResults(engineId, callBack, {
    page: searchPage,
    perPage: 10,
    query: searchValue,
    filters: { Category: [filterTag] },
  })
}
