import { addHeightToImagePath, addWidthToImagePath } from '../helpers/query-string';
import { ImageSitecoreItem } from '../types/generated/sitecore.interface';

export const galleryViewTargetSize = 500;
export const galleryThumbnailTargetSize = 80;

const basketLineTargetSize = 126;
const miniBasketLineTargetSize = 100;

const scaleImageToTargetSize = (imageUrl: string, targetSize: number): string => {
    let url = imageUrl;
    url = addWidthToImagePath(url, targetSize);
    url = addHeightToImagePath(url, targetSize);
    return url;
};

export const getImageURLForGalleryView = (image: ImageSitecoreItem, retinaSize = false): string =>
    scaleImageToTargetSize(image.Url || '', galleryViewTargetSize * (retinaSize ? 2 : 1));

export const getImageURLForGalleryThumbnail = (image: ImageSitecoreItem, retinaSize = false): string =>
    scaleImageToTargetSize(image.Url || '', galleryThumbnailTargetSize * (retinaSize ? 2 : 1));

const getImageSrc = (url: string, size: number): { src: string; srcSet: string } => {
    const src = scaleImageToTargetSize(url, size);
    const src2x = scaleImageToTargetSize(url, size * 2);
    return {
        src,
        srcSet: `${src}, ${src2x} 2x`,
    };
};

export const getImageSrcForBasketView = (url: string): { src: string; srcSet: string } =>
    getImageSrc(url, basketLineTargetSize);

export const getImageSrcForMiniBasketView = (url: string): { src: string; srcSet: string } =>
    getImageSrc(url, miniBasketLineTargetSize);
