import create from 'zustand';
import { IS_BROWSER } from '../../../../constants';
import { Notifications, NotificationsActions, NotificationEntry } from '../model/notifications-model';

const noAction = async () => undefined;

export const createNoActionNotification = (): Notifications => ({
    addNotification: noAction,
    closeNotification: noAction,
});

export const useNotifications = create<Notifications>((setNotifications, getNotifications) => {
    if (!IS_BROWSER) {
        return createNoActionNotification();
    }

    const actions: NotificationsActions = {
        addNotification: async (message: string) => {
            const state = getNotifications();
            const { current, messageQueue } = state;
            if (
                message === current?.message ||
                messageQueue?.some(({ message: queuedMessage }) => message === queuedMessage)
            ) {
                return;
            }
            const newNotification: NotificationEntry = {
                message,
            };
            if (current) {
                if (messageQueue) {
                    setNotifications({ messageQueue: messageQueue.concat(newNotification) });
                } else {
                    setNotifications({ messageQueue: [newNotification] });
                }
            } else {
                setNotifications({ current: newNotification });
            }
        },
        closeNotification: () => {
            const state = getNotifications();
            if (!state.current) {
                return;
            }
            const messageQueue = [...(state.messageQueue || [])];
            const newCurrent = messageQueue.splice(0, 1)?.[0];
            setNotifications({ current: newCurrent, messageQueue });
        },
    };

    return { ...actions, current: undefined, messageQueue: undefined };
});
