import React, { forwardRef } from 'react'

import MenuItem from '@material-ui/core/MenuItem'
import Link from '@material-ui/core/Link'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import SvgIcon from '@material-ui/core/SvgIcon'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
  navItems: {
    padding: '12px 16px',
    borderRadius: 6,
    border: ({ inline }: ILoginNavItem) =>
      inline ? `solid 1px ${theme.palette.grey[300]}` : null,
    color: theme.palette.common.black,
    display: 'flex',
    width: '100%',
    alignItems: 'center',
    textDecoration: 'none',
    '&:visited': {
      color: theme.palette.common.black,
    },
    '& svg': {
      color: theme.palette.primary.main,
      fill: theme.palette.primary.main,
    },
    '&:hover, &:focus': {
      '& svg': {
        color: theme.palette.common.white,
        fill: theme.palette.common.white,
      },
      borderRadius: 6,
      color: 'white !important',
      backgroundColor: theme.palette.primary.main,
    },
  },
  listIcon: {
    minWidth: 'unset',
    marginRight: 12,
  },
  icon: {
    width: ({ inline }: ILoginNavItem) => (inline ? 24 : 18),
    height: ({ inline }: ILoginNavItem) => (inline ? 24 : 18),
  },
  title: {
    '& .MuiListItemText-primary': {
      ...theme.typography.body2,
    },
  },
  inlineTitle: {
    '& .MuiListItemText-primary': {
      ...theme.typography.subtitle1,
    },
  },
  description: {
    '& .MuiListItemText-primary': {
      ...theme.typography.body2,
    },
  },
  inlineDescription: {
    ...theme.typography.subtitle2,
  },
}))

interface ILoginNavItem {
  title: string
  icon: any
  url: string
  inline?: boolean
  description: string
}

const LoginNavItems = forwardRef(
  ({ title, description, url, icon, inline }: ILoginNavItem, ref) => {
    const classes = useStyles({ inline })
    return (
      <MenuItem
        component={Link}
        ref={ref}
        className={classes.navItems}
        title={description}
        href={url}
      >
        <ListItemIcon className={classes.listIcon}>
          <SvgIcon
            className={classes.icon}
            component={icon}
            viewBox="0 0 48 48"
          />
        </ListItemIcon>
        {inline ? (
          <div>
            <ListItemText
              className={inline ? classes.inlineTitle : classes.title}
              primary={title}
            />
            <ListItemText
              className={
                inline ? classes.inlineDescription : classes.description
              }
              primary={description}
            />
          </div>
        ) : (
          <ListItemText
            className={inline ? classes.inlineTitle : classes.title}
            primary={title}
          />
        )}
      </MenuItem>
    )
  },
)

export default LoginNavItems
