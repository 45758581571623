import React, { Dispatch, SetStateAction, useEffect, useRef } from 'react'
import { iconsMap } from '@tdcerhverv/parrotfish/dist/icons/icons'
import { Link as GatsbyLink } from 'gatsby'

//Mui Components
import Link from '@material-ui/core/Link'
import SvgIcon from '@material-ui/core/SvgIcon'
import { makeStyles } from '@material-ui/styles'
import { Theme, useMediaQuery, useTheme } from '@material-ui/core'
import { IMegaMenuItems } from './MegaMenu'
import { IGatsbyImageData } from 'gatsby-plugin-image'
import { IParrotfishIcon } from '../../ParrotfishIcon'

interface INavigationItem {
  title?: string
  link?: string
  href?: string
  active?: boolean
  onClick?: Dispatch<SetStateAction<number | boolean>>
  index?: number
  megaMenuItems?: IMegaMenuItems[]
  image?: IGatsbyImageData
  isAnimated: boolean
  leftIcon?: IParrotfishIcon
}

const useStyles = makeStyles<Theme, INavigationItem>(theme => ({
  root: {
    display: 'block',
    padding: '0 14px',

    [theme.breakpoints.down('sm')]: {
      padding: '0 8px',
    },

    '&:first-child': {
      paddingLeft: 0,
    },
    '&:last-child': {
      paddingRight: 0,
    },
    '&:hover button': {
      color: `${theme.palette.primary.main} !important`,
    },
  },
  link: {
    ...theme.typography.subtitle1,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    textDecoration: 'none',
    cursor: 'pointer',
    paddingBottom: 8,
    marginBottom: -8,
    color: ({ isAnimated }) =>
      isAnimated ? theme.palette.common.black : theme.palette.common.white,

    '& > span': {
      display: 'flex',
      alignItems: 'center',
    },

    '&:visited': {
      color: ({ isAnimated }) =>
        isAnimated ? theme.palette.common.black : theme.palette.common.white,
    },

    '&:hover': {
      color: theme.palette.primary.main,
      fill: theme.palette.primary.main,
      '&:visited': {
        color: theme.palette.primary.main,
      },
    },
    '&:focus': {
      color: ({ isAnimated }) =>
        isAnimated ? theme.palette.primary.main : theme.palette.common.white,
      outlineColor: theme.palette.primary.main,
      fill: theme.palette.primary.main,
    },
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      padding: '10px 0',
      fontSize: 19,
    },
  },
  active: {
    borderBottom: `2px solid ${theme.palette.primary.main}`,
    color: `${theme.palette.primary.main} !important`,
  },
  expandIcon: {
    display: 'none',
    width: 16,
    height: 16,

    [theme.breakpoints.down('sm')]: {
      display: 'block',
    },
  },
  leftIcon: {
    display: 'none',
    width: 20,
    height: 20,
    marginRight: 12,

    [theme.breakpoints.down('sm')]: {
      display: 'block',
    },
  },
}))

const NavigationItem = ({
  title,
  href,
  active,
  isAnimated,
  index,
  onClick,
  leftIcon,
}: INavigationItem) => {
  const classes = useStyles({ isAnimated })
  const isMobile = useMediaQuery(useTheme().breakpoints.down('sm'))
  const ref = useRef(null)
  useEffect(() => {
    if (!active) {
      ref.current.blur()
    }
  }, [active])

  return (
    <li className={classes.root}>
      <Link
        ref={ref}
        component={href ? GatsbyLink : 'button'}
        variant="subtitle1"
        to={href && href}
        onClick={() => onClick && onClick(index)}
        className={`${classes.link} ${active ? classes.active : ''}`}
        aria-current={active ? true : false}
        data-id={`cy-${title.toLowerCase()}`}
        {...(href ? { activeClassName: 'active-link' } : {})}
      >
        {isMobile ? (
          <>
            <span>
              {leftIcon && (
                <SvgIcon
                  className={classes.leftIcon}
                  component={leftIcon}
                  viewBox="0 0 48 48"
                />
              )}
              {title}
            </span>
            <SvgIcon
              className={classes.expandIcon}
              component={iconsMap['Expand_right']}
              viewBox="0 0 48 48"
            />
          </>
        ) : (
          title
        )}
      </Link>
    </li>
  )
}

export default NavigationItem
