import {
  Box,
  CircularProgress,
  IconButton,
  makeStyles,
  Typography,
} from '@material-ui/core'
import React, { ReactElement } from 'react'
import { StockStatusIndicator } from '../StockStatusIndicator/StockStatusIndicator'
import { QuantityControl } from '../QuantityControl/QuantityControl'
import { getImageSrcForMiniBasketView } from '../../helpers/image'
import { translate } from '../../helpers/translate'
import { TrashIcon } from '../../icons/generated/TrashIcon'
import { useBasket } from '../../modules/basket'
import { LocalizedBasketLine } from '../../types/generated/api.interface'
import { routes } from '../Router/routes'
import { Link } from 'gatsby'

type Props = {
  data: LocalizedBasketLine
  readonly?: boolean
}

const useStyles = makeStyles(theme => {
  const borderColor = theme.palette.grey[100]
  return {
    wrapper: {
      width: '100%',
      boxSizing: 'border-box',
      backgroundColor: theme.palette.background.default,
      border: `1px solid ${borderColor}`,
      borderRadius: '6px',
      boxShadow:
        '0px 1px 1px rgba(25, 34, 40, 0.1), 0px 0px 1px rgba(25, 34, 40, 0.05)',
      padding: `${theme.spacing(1.5)}px ${theme.spacing(0.75)}px`,
      display: 'flex',
      '& a': {
        color: 'inherit',
      },
    },
    detailsAndRemove: {
      display: 'flex',
      justifyContent: 'space-between',
    },
    details: {
      marginTop: theme.spacing(0.5),
      marginLeft: theme.spacing(0.5),
      marginRight: theme.spacing(1),
    },
    readonlyAmount: {
      marginLeft: theme.spacing(0.5),
    },
    controlAndPrice: {
      marginRight: theme.spacing(1),
      display: 'flex',
      flexWrap: 'wrap',
      justifyContent: 'space-between',
      alignItems: 'baseline',
      '& > *': {
        marginTop: theme.spacing(1.5),
      },
    },
  }
})

const ProductImage = ({ imageUrl }: { imageUrl: string }) => (
  <img
    height="100px"
    width="100px"
    {...getImageSrcForMiniBasketView(imageUrl)}
    loading="lazy"
  />
)

const MiniBasketLineProductInfo = React.memo(
  ({ data, readonly = false }: Props): ReactElement => {
    const {
      imageUrl = '',
      brand,
      name: productName,
      variantId: productId,
      colorName,
      stockStatus,
      amount,
      formattedTotalPriceExVat,
      remainingStock = 1,
      stockText = '',
    } = data
    const classes = useStyles()
    const basket = useBasket()

    if (!productId) {
      return <></>
    }
    if (!imageUrl && !productName) {
      return (
        <div className={classes.wrapper}>
          <Box m={4} mr={30}>
            <CircularProgress size={'2rem'} />
          </Box>
        </div>
      )
    }

    const productLink = readonly
      ? ''
      : routes.productPage.build(productId, productName, colorName)

    return (
      <div className={classes.wrapper}>
        {readonly ? (
          <ProductImage imageUrl={imageUrl} />
        ) : (
          <Link to={productLink}>
            <ProductImage imageUrl={imageUrl} />
          </Link>
        )}
        <Box width="100%">
          <div className={classes.detailsAndRemove}>
            <div className={classes.details}>
              <Typography
                variant="overline"
                color="textSecondary"
                component="p"
              >
                {productLink ? <Link to={productLink}>{brand}</Link> : brand}
              </Typography>
              <Typography
                variant="subtitle1"
                color="textPrimary"
                component={productLink ? Link : 'h6'}
                to={productLink}
              >
                {productName}
              </Typography>
              <Box mt={1}>
                <StockStatusIndicator color={stockStatus} text={stockText} />
              </Box>
            </div>
            {!readonly && (
              <div>
                <IconButton
                  size="small"
                  onClick={() => {
                    if (basket.lines?.length === 1) {
                      basket.closeMiniBasket()
                    }
                    basket.removeFromBasket(productId)
                  }}
                  aria-label={translate('basket.remove')}
                >
                  <TrashIcon fontSize="inherit" />
                </IconButton>
              </div>
            )}
          </div>
          <div className={classes.controlAndPrice}>
            {readonly ? (
              <Typography
                className={classes.readonlyAmount}
                component="div"
                variant="subtitle1"
              >
                {translate('basket.basketLineAmount', {
                  amount: String(amount),
                })}
              </Typography>
            ) : (
              <QuantityControl
                min={1}
                max={remainingStock}
                quantity={amount || 0}
                onIncrease={() => basket.addToBasket(productId, 1)}
                onDecrease={() => basket.addToBasket(productId, -1)}
                onValueChange={newAmount =>
                  basket.setAmount(productId, newAmount)
                }
              />
            )}
            <Typography noWrap component="div" variant="subtitle1">
              {formattedTotalPriceExVat}
            </Typography>
          </div>
        </Box>
      </div>
    )
  },
)

MiniBasketLineProductInfo.displayName = 'MiniBasketLineProductInfo'

export { MiniBasketLineProductInfo }
