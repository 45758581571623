import { updateEntry } from '../helpers/update-entry';
import { Basket } from '../model/basket-model';

export const setAmount = (basketLines: Basket['lines'] = [], productId: string, amount: number): Basket['lines'] => {
    const currentProductIndex = basketLines.findIndex((line) => line.variantId === productId);
    if (currentProductIndex === -1) {
        return;
    }
    return updateEntry(
        basketLines,
        {
            amount,
        },
        currentProductIndex,
    );
};
