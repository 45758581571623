import React, { useCallback, useEffect, useState } from 'react'
import DOMPurify from 'dompurify'

// Components & Context
import { SearchResultItem } from '../Search'

// Utils
import {
  fetchCludoClickingTrack,
  fetchCludoTracking,
  filterSearchRequest,
  fullSearchRequest,
} from '../../../../utils/cludoRequest'

// MUI
import { makeStyles, Theme, useTheme } from '@material-ui/core/styles'
import Chip from '@material-ui/core/Chip'
import CircularProgress from '@material-ui/core/CircularProgress'
import Grid from '@material-ui/core/Grid'
import Pagination from '@material-ui/lab/Pagination'
import Typography from '@material-ui/core/Typography'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { Divider } from '@material-ui/core'
import { Link } from 'gatsby'
import { getDeviceType } from '../../../../utils/getDeviceType'
import { useConsent } from '../../../../hooks/useConsent'
import { createTrackingCookie } from '../../../../utils/createTrackingCookie'

import { useLocation } from '@reach/router'
import { IS_LOCALHOST } from '../../../../../constants'
import trackingEvent, {
  FormattedPageInfo,
  PageInfo,
} from '../../../../utils/trackingEvent'

export interface ISearchResultsStyles {
  filtersLoading: boolean
  resultsLoading: boolean
}
const useStyles = makeStyles<Theme, ISearchResultsStyles>(theme => ({
  resultSummaryWrapper: {
    margin: '20px 0 48px 0',
    textAlign: 'center',
    [theme.breakpoints.down('xs')]: {
      margin: '20px 0 24px 0',
    },
  },
  searchSpinner: {
    display: ({ filtersLoading: _, resultsLoading }) =>
      resultsLoading ? 'flex' : 'none',
    justifyContent: 'center',
    marginTop: 20,
  },
  tagsContainer: {
    display: ({ filtersLoading: _, resultsLoading }) =>
      resultsLoading ? 'none' : 'flex',
    justifyContent: 'center',
    [theme.breakpoints.down('xs')]: {
      flexWrap: 'wrap',
      marginBottom: 24,
    },
  },
  tagItemWrapper: {
    padding: '0 12px 24px 0',
    [theme.breakpoints.down('xs')]: {
      padding: '0 12px 12px 0',
    },
  },
  tagItemWrapperActive: {
    padding: '0 12px 24px 0',
    [theme.breakpoints.down('xs')]: {
      padding: '0 12px 12px 0',
    },
    '& .MuiChip-clickable:hover': {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.common.white,
      '&:not(.MuiChip-clickable:active)': {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.common.white,
      },
    },
    '& .MuiChip-root': {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.common.white,
      '&:hover': {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.common.white,
      },
    },
  },
  suggestionWrapper: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: 8,
    '& a': {
      textDecorationThickness: 0.5,
    },
  },
  filterSpinner: {
    display: ({ filtersLoading }) => (filtersLoading ? 'flex' : 'none'),
    justifyContent: 'center',
  },
  bannerContainer: {
    display: ({ filtersLoading }) => (filtersLoading ? 'none' : 'block'),
    width: 700,
    [theme.breakpoints.down('xs')]: {
      width: 294,
    },
  },
  paginationWrapper: {
    display: ({ filtersLoading }) => (filtersLoading ? 'none' : 'flex'),
    justifyContent: 'center',
    padding: '50px 0 0 0',
  },
}))

export default function SearchResultsList({ engineId, queryParams }) {
  const [activeTag, setActiveTag] = useState('Alle resultater')
  const [paginationCount, setPaginationCount] = useState(1)
  const [paginationNumber, setPaginationNumber] = useState(1)
  const [splitResults, setSplitResults] = useState([])
  const [numberOfResults, setNumberOfResults] = useState(0)
  const [misspellingCorrection, setMisspellingCorrection] = useState('')
  const [tags, setTags] = useState([])
  const [resultsLoading, setResultsLoading] = useState(true)
  const [filtersLoading, setFiltersLoading] = useState(false)
  const [banners, setBanners] = useState([])
  const [showResultsNumber, setShowResultsNumber] = useState(false)
  const [suggestion, setSuggestion] = useState('')
  const { cookie_cat_statistic } = useConsent()

  const isMobile = useMediaQuery(useTheme().breakpoints.down('xs'))
  const classes = useStyles({
    filtersLoading: filtersLoading,
    resultsLoading: resultsLoading,
  })

  const { origin, pathname, href } = useLocation()
  const fullURL = href
  const urlWithoutQuery = origin + pathname

  useEffect(() => {
    setResultsLoading(true)
    setSplitResults([])
    setNumberOfResults(0)
    setPaginationNumber(1)
    if (queryParams.cludoquery) {
      fullSearchRequest(
        queryParams.cludoquery,
        paginationNumber,
        engineId,
        data => {
          const {
            Facets,
            TotalDocument,
            TypedDocuments,
            Banners,
            FixedQuery,
            Suggestions,
          } = data
          const newTags = [{ text: 'Alle resultater' }]
          Facets.Category.Items.map(cat => newTags.push({ text: cat.Key }))
          setTags(newTags)
          setNumberOfResults(TotalDocument)
          trackingAnalytics(TotalDocument)
          setPaginationCount(Math.ceil(TotalDocument / 10))
          setSplitResults(TypedDocuments)
          setResultsLoading(false)
          setBanners(Banners)
          setMisspellingCorrection(FixedQuery)
          setSuggestion(Suggestions[0]?.Text)
          if (
            ((window?.location?.href?.includes('tdc.dk') ||
              window?.location?.href?.includes('yousee.dk')) &&
              cookie_cat_statistic) ||
            IS_LOCALHOST
          ) {
            fetchCludoTracking(engineId, data => null, {
              sw: queryParams.cludoquery,
              refurl: window.history.state?.refurl,
              refpt: window.history.state?.refpt,
              dt: getDeviceType(),
              rc: TotalDocument.toString(),
              ban: Banners.length.toString(),
              bnrs: Banners.map(banner => banner['Id']).toString(),
              sid: createTrackingCookie('csid'),
            })
          }
        },
      )
    }
  }, [queryParams.cludoquery])

  useEffect(() => {
    if (
      queryParams.cludoquery &&
      !resultsLoading &&
      !(numberOfResults === 0 && banners.length > 0)
    ) {
      setShowResultsNumber(true)
    } else {
      setShowResultsNumber(false)
    }
  }, [queryParams.cludoquery, resultsLoading, numberOfResults, banners.length])

  const onFilterClick = useCallback(
    text => {
      setActiveTag(text)
      setFiltersLoading(true)
      setPaginationNumber(1)
      if (text === 'Alle resultater') {
        fullSearchRequest(queryParams.cludoquery, 1, engineId, data => {
          const { TotalDocument, TypedDocuments } = data

          setNumberOfResults(TotalDocument)
          setPaginationCount(Math.ceil(TotalDocument / 10))
          setSplitResults(TypedDocuments)
          setFiltersLoading(false)
        })
      } else {
        filterSearchRequest(queryParams.cludoquery, 1, text, engineId, data => {
          const { TotalDocument, TypedDocuments } = data
          setNumberOfResults(TotalDocument)
          setPaginationCount(Math.ceil(TotalDocument / 10))
          setSplitResults(TypedDocuments)
          setFiltersLoading(false)
        })
      }
    },
    [
      setActiveTag,
      setSplitResults,
      setPaginationNumber,
      splitResults,
      queryParams,
    ],
  )
  const onPaginationChange = useCallback(
    (event, value) => {
      setFiltersLoading(true)
      setPaginationNumber(value)
      if (activeTag === 'Alle resultater') {
        fullSearchRequest(queryParams.cludoquery, value, engineId, data => {
          setNumberOfResults(data.TotalDocument)
          setPaginationCount(Math.ceil(data.TotalDocument / 10))
          setSplitResults(data.TypedDocuments)
          setFiltersLoading(false)
        })
      } else {
        filterSearchRequest(
          queryParams.cludoquery,
          value,
          activeTag,
          engineId,
          data => {
            setNumberOfResults(data.TotalDocument)
            setPaginationCount(Math.ceil(data.TotalDocument / 10))
            setSplitResults(data.TypedDocuments)
            setFiltersLoading(false)
          },
        )
      }
    },

    [setPaginationNumber, queryParams, activeTag],
  )

  const onLinkClick = (url, title) => {
    if (
      ((window?.location?.href?.includes('tdc.dk') ||
        window?.location?.href?.includes('yousee.dk')) &&
        cookie_cat_statistic) ||
      IS_LOCALHOST
    ) {
      fetchCludoClickingTrack(engineId, data => null, {
        sw: queryParams.cludoquery,
        refurl: window.location.href,
        refpt: document.title,
        dt: getDeviceType(),
        clurl: url,
        title: title,
        sid: createTrackingCookie('csid'),
      })
    }
  }

  const onBannerClick = index => {
    if (
      (window?.location?.href?.includes('tdc.dk') ||
        window?.location?.href?.includes('yousee.dk')) &&
      cookie_cat_statistic
    ) {
      fetchCludoClickingTrack(engineId, data => null, {
        sw: queryParams.cludoquery,
        ls: 'banner',
        cloi: banners[index]['Id'].toString(),
        refurl: window.location.href,
        refpt: document.title,
        dt: getDeviceType(),
        sid: createTrackingCookie('csid'),
      })
    }
  }

  const sanitizedBanner = banner => ({
    __html: DOMPurify.sanitize(banner),
  })
  // Adobe Analytics - Global Variables
  const trackingAnalytics = TotalDocument => {
    trackingEvent({
      event: 'page_view',
      pageInfo: {
        pageName: 'søge resultater - tdc',
        divisionSection: 'enterprise',
        siteSection: 'tdc',
        subSection1: 'search-results',
        pageType: 'product',
        id: 'tdc-search-results',
        pageVariant: 'ENTERPRISE',
        urlWithoutQuery: urlWithoutQuery,
        fullURL: fullURL,
      } as FormattedPageInfo & PageInfo,
      searchInfo: {
        term: queryParams.cludoquery,
        resultCount: TotalDocument,
      },
    })
  }
  return (
    <>
      {misspellingCorrection !== '' && numberOfResults > 0 ? (
        <div>
          <div className={classes.resultSummaryWrapper}>
            {showResultsNumber && (
              <Typography variant="body1">
                Din søgning på <b>”{queryParams.cludoquery}”</b> gav 0
                resultater <br />
                Viser istedet {numberOfResults} resultater for{' '}
                <b>”{misspellingCorrection}”</b>
              </Typography>
            )}
          </div>
        </div>
      ) : (
        numberOfResults >= 0 && (
          <div className={classes.resultSummaryWrapper}>
            {showResultsNumber && (
              <Typography variant="body1">
                Din søgning på <b>”{queryParams.cludoquery}”</b> gav{' '}
                {numberOfResults} resultater
              </Typography>
            )}
            {suggestion && (
              <Typography variant="body1" className={classes.suggestionWrapper}>
                Mente du: ”
                <Link to={`/search-results?cludoquery=${suggestion}`}>
                  {suggestion}
                </Link>
                ”?
              </Typography>
            )}
          </div>
        )
      )}
      {numberOfResults > 0 ? (
        <div>
          <div className={classes.tagsContainer}>
            {tags.map((tagItem, tagIndex) => {
              return (
                <div
                  key={tagIndex}
                  className={
                    activeTag === tagItem.text
                      ? classes.tagItemWrapperActive
                      : classes.tagItemWrapper
                  }
                >
                  <Chip
                    label={tagItem.text}
                    onClick={() => onFilterClick(tagItem.text)}
                  />
                </div>
              )
            })}
          </div>
          {isMobile && <Divider />}
        </div>
      ) : (
        <div className={classes.searchSpinner}>
          <CircularProgress />
        </div>
      )}
      <div className={classes.filterSpinner}>
        <CircularProgress />
      </div>
      <div className={classes.bannerContainer}>
        {banners.map((item, index) => {
          return (
            <Grid
              key={index}
              dangerouslySetInnerHTML={sanitizedBanner(item.Banner)}
              onClick={() => onBannerClick(index)}
            />
          )
        })}
      </div>
      {splitResults.map((item, index) => {
        return (
          <SearchResultItem
            item={item}
            index={index}
            key={index}
            filtersLoading={filtersLoading}
            onLinkClick={onLinkClick}
          />
        )
      })}
      {numberOfResults > 10 && (
        <div className={classes.paginationWrapper}>
          <Pagination
            count={paginationCount}
            onChange={onPaginationChange}
            page={paginationNumber}
          ></Pagination>
        </div>
      )}
    </>
  )
}
