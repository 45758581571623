import React from 'react'
import { LoginMenuItems } from '../../data/loginData'
import { iconsMap } from '@tdcerhverv/parrotfish/dist/icons/icons'
import LoginNavItems from '../LoginNavItems'
import { ILoginMenu } from '.'

//MUI Component
import { makeStyles } from '@material-ui/styles'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import SvgIcon from '@material-ui/core/SvgIcon'
import { Theme } from '@material-ui/core'

const useStyles = makeStyles<Theme>(theme => ({
  LoginMenuControls: {
    display: 'none',
    [theme.breakpoints.down('sm')]: {
      display: 'block',
    },
  },
  title: {
    ...theme.typography.subtitle1,
    display: 'flex',
    alignItems: 'center',
    fontSize: 18,
    fontWeight: 500,
    background: 'none',
    border: 0,
    padding: '0 12px',
    [theme.breakpoints.down('sm')]: {
      cursor: 'pointer',
    },
  },
  titleIcon: {
    width: 16,
    height: 16,
    marginRight: 10,
  },
  navItems: {
    margin: 0,
    padding: 0,
    '& > *': {
      marginBottom: 16,
    },
  },
}))

const LoginMenu = ({ handleClose }: ILoginMenu) => {
  const classes = useStyles()
  return (
    <div>
      <Box
        className={classes.LoginMenuControls}
        mb={5}
        display="flex"
        justifyContent="space-between"
      >
        <Typography
          onClick={() => handleClose && handleClose(false)}
          component="button"
          className={classes.title}
        >
          <SvgIcon
            className={classes.titleIcon}
            component={iconsMap['Expand_left']}
            viewBox="0 0 48 48"
          />
          <SvgIcon
            className={classes.titleIcon}
            component={iconsMap['Lock']}
            viewBox="0 0 48 48"
          />
          <span>Log ind</span>
        </Typography>
      </Box>
      <ul className={classes.navItems}>
        {LoginMenuItems.map((menuItem, index) => (
          <LoginNavItems inline key={index} {...menuItem} />
        ))}
      </ul>
    </div>
  )
}

export default LoginMenu
