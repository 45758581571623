import removeHttpPrefix from '../removeHttpsPrefix'

export default function getIdFromString(string: string) {
  if (string == null) {
    //== instead of === will catch both null and undefined
    console.warn('[getIdFromString] provided id was null or undefined')
    return ''
  }

  const validIdRegex = /^[a-zA-Z][a-zA-Z0-9-_]+$/
  const isValidId = string.match(validIdRegex)

  if (isValidId) return string.toLowerCase() //if it is already a valid id, then just return it

  //remove http:// or https:// if it is provided
  let modifiedString = removeHttpPrefix(string)

  modifiedString = modifiedString
    .replace(/^[^a-zA-Z]+/, '') //remove initial characters if it is not a letter
    .replace(/\s+/g, '-') // replace white space with '-'
    .replace(/\.+/g, '-') // replace '.' with '-'
    .replace(/:+/g, '-') // replace ':' with '-'
    .replace(/\/+/g, '-') // replace '/' with '-'
    .replace(/[æÆ]/g, 'ae') // replace 'æ' with 'ae'
    .replace(/[øØ]/g, 'oe') // replace 'ø' with 'oe'
    .replace(/[åÅ]/g, 'aa') // replace 'å' with 'aa'
    .replace(/[^a-zA-Z0-9-_]/g, '') // remove any other invalid characters

  const nowItsValid = modifiedString.match(validIdRegex)

  if (nowItsValid || modifiedString === '') return modifiedString.toLowerCase()

  //if the id still isn't valid, then just return an empty string instead. warn in console so we can identify possible issues
  console.warn(
    `[getIdFromString] attempted to parse string ${string} unsuccessfully. recieved: ${modifiedString}`,
  )
  return ''
}
