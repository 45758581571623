import {
  Box,
  Button,
  IconButton,
  makeStyles,
  Theme,
  Typography,
  useMediaQuery,
} from '@material-ui/core'
import React, { ReactElement, useEffect, useState } from 'react'
import { Link } from 'gatsby'
import { BasketPriceTable } from '../BasketPriceTable'
import { ErrorMessage } from '../ErrorMessage'
import { MiniBasketLineProductInfo } from '../MiniBasketLineProductInfo/MiniBasketLineProductInfo'
import { routes } from '../Router/routes'
import { translate } from '../../helpers/translate'
import CloseIcon from '../../icons/generated/CloseIcon'
import { useBasket } from '../../modules/basket'

type Props = {
  onClose: () => void
}

type StyleProps = { smallScreen: boolean }

const useStyles = makeStyles<Theme, StyleProps>(theme => {
  const hPadding = (props: StyleProps) =>
    props.smallScreen ? theme.spacing(1) : theme.spacing(4.125)
  return {
    wrapper: {
      maxWidth: theme.spacing(57.25),
      height: `calc(100vh - ${theme.spacing(8.25)}px)`,
      display: 'flex',
      flex: 'auto',
      flexDirection: 'column',
    },
    rightAlign: {
      display: 'flex',
      justifyContent: 'flex-end',
    },
    productList: {
      overflowY: 'auto',
      flexGrow: 1,
      marginTop: theme.spacing(3.5),
      marginBottom: theme.spacing(3.5),
      paddingLeft: hPadding,
      paddingRight: hPadding,
      '& > div:not(:first-child)': {
        marginTop: theme.spacing(1.5),
      },
    },
    header: {
      flexGrow: 0,
      paddingTop: props =>
        props.smallScreen ? theme.spacing(2) : theme.spacing(4.125),
      paddingLeft: hPadding,
      paddingRight: hPadding,
    },
    footer: {
      borderTop: `1px solid ${theme.palette.grey[100]}`,
      flexGrow: 0,
      paddingLeft: hPadding,
      paddingRight: hPadding,
      paddingBottom: theme.spacing(2),
    },
  }
})

export const MiniBasket = (props: Props): ReactElement => {
  const { onClose } = props
  const smallScreen = useMediaQuery('(max-width:458px)') // TODO: Adjust to xs when breakpoints are fixed in the theme
  const [styleProps, setStyleProps] = useState<StyleProps>({ smallScreen })
  const classes = useStyles(styleProps)
  const basket = useBasket()

  useEffect(() => {
    setStyleProps({ smallScreen })
  }, [smallScreen])

  return (
    <section className={classes.wrapper}>
      <div className={classes.header}>
        <div className={classes.rightAlign}>
          <IconButton
            size="small"
            onClick={onClose}
            aria-label={translate('basket.closeMiniBasket')}
          >
            <CloseIcon fontSize="inherit" />
          </IconButton>
        </div>
        <Typography component="h2" variant="h5">
          {translate('basket.miniBasketHeader')}
        </Typography>
      </div>
      <div className={classes.productList}>
        {basket.lines?.map(line => (
          <MiniBasketLineProductInfo key={line.variantId} data={line} />
        ))}
      </div>
      <div className={classes.footer}>
        {basket.isAboveMaximumTotal && (
          <Box mt={3} mb={1.5}>
            <ErrorMessage
              message={translate('basket.errors.orderAboveLimit')}
            />
          </Box>
        )}
        <Box mt={3} mb={3}>
          <BasketPriceTable />
        </Box>
        {!!basket.amount && (
          <div className={classes.rightAlign}>
            {smallScreen ? (
              <Button
                disabled={basket.isAboveMaximumTotal}
                color="primary"
                component={Link}
                to={routes.checkout.route}
              >
                {translate('basket.proceedToCheckout')}
              </Button>
            ) : (
              <Button color="primary" component={Link} to={routes.basket.route}>
                {translate('basket.goToBasketPage')}
              </Button>
            )}
          </div>
        )}
      </div>
    </section>
  )
}
