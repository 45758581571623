import React, { useState, useRef } from 'react'

import { INavStyles } from '../Navigation.types'
import { Variants } from '@tdcerhverv/mui-theme'
import { LoginMenuItems } from '../data/loginData'
import { Link } from 'gatsby'
import LoginNavItems from './LoginNavItems'

//MUI Components
import Button from '@material-ui/core/Button'
import { makeStyles } from '@material-ui/styles'
import { Theme } from '@material-ui/core/styles'
import Menu from '@material-ui/core/Menu'

const useStyles = makeStyles((theme: Theme) => ({
  navLinks: {
    color: ({ isAnimated }: INavStyles) =>
      isAnimated ? theme.palette.common.black : theme.palette.common.white,
    cursor: 'pointer',
    fontWeight: 500,
    fontSize: 15,
    textDecoration: 'none',
    '&:visited': {
      color: ({ isAnimated }: INavStyles) =>
        isAnimated ? theme.palette.common.black : theme.palette.common.white,
    },
    '&:hover, &:focus': {
      color: ({ isAnimated }: INavStyles) =>
        isAnimated ? theme.palette.primary.main : theme.palette.common.white,
    },
  },
  loginMenu: {
    marginTop: 16,
    '& .MuiPaper-root': {
      width: 287,
    },
    '& .MuiList-root': {
      padding: 16,
    },
  },
}))

const RightNavigationItems = ({ isAnimated }: INavStyles) => {
  const ref = useRef(null)
  const classes = useStyles({ isAnimated })
  const [anchorElement, setAnchorElement] = useState(null)

  const setMenuOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorElement(event.currentTarget)
  }
  const setMenuClose = () => {
    setAnchorElement(null)
  }

  return (
    <>
      <Link
        className={classes.navLinks}
        to="https://tdc.dk/kontakt"
        title="Kontakt"
        data-id="cy-kontakt"
      >
        Kontakt
      </Link>
      <Link
        className={classes.navLinks}
        to="https://tdc.dk/kundeservice"
        title="Hjælp"
        data-id="cy-help"
      >
        Hjælp
      </Link>
      <Button
        id="login-button"
        data-testid="login-button"
        size="small"
        variant="outlined"
        className={!isAnimated ? Variants.Button.Inverted : ``}
        aria-controls="selfservice-menu"
        aria-haspopup="true"
        onClick={setMenuOpen}
      >
        Log ind
      </Button>
      <Menu
        ref={ref}
        className={classes.loginMenu}
        variant="menu"
        id="selfservice-menu"
        anchorEl={anchorElement}
        disableScrollLock
        keepMounted
        autoFocus
        open={Boolean(anchorElement)}
        onClose={setMenuClose}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        {LoginMenuItems.map((menuItem, index) => (
          <LoginNavItems key={index} {...menuItem} />
        ))}
      </Menu>
    </>
  )
}

export default RightNavigationItems
