import React from 'react'
import NavigationItem from './NavigationItem'
import RightNavigationItems from './RightNavigationItems'
import { iconsMap } from '@tdcerhverv/parrotfish/dist/icons/icons'
import { INavItems } from '../Navigation.types'

//MUI Components
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import IconButton from '@material-ui/core/IconButton'
import SvgIcon from '@material-ui/core/SvgIcon'
import { makeStyles } from '@material-ui/styles'
import { Theme } from '@material-ui/core'

interface INavigationItems {
  navItems?: INavItems[]
  handleSearchOpen?: () => void
  searchOpen?: boolean
  handleActiveNav?: (navItem: number) => void
  activeNav?: number | null
  animationCondition?: boolean
  darkFontTopNav?: boolean
}

const useStyles = makeStyles<Theme, INavigationItems>(theme => ({
  rightNavigationWrapper: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',

    '& > *': {
      marginLeft: 24,
    },
    '& > *:first-child': {
      marginLeft: 0,
      marginRight: -11,
    },
    [theme.breakpoints.down('sm')]: {
      '& > *': {
        marginLeft: 14,
      },
    },
  },
  navItemsWrapper: {
    padding: 0,
    margin: 0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'flex-start',
    },
  },
  searchButton: {
    color: 'inherit',
    // hover on this component
    '&:hover': {
      backgroundColor: 'transparent',
    },
    // hover on child
    '& :hover': {
      color: theme.palette.primary.main,
      '& $searchIcon': {
        fill: theme.palette.primary.main,
      },
    },
    '&:active': {
      backgroundColor: 'transparent',
      color: theme.palette.primary.main,
    },
  },
  searchIcon: {
    width: 16,
    height: 16,
    color: 'inherit',
    fill: ({ darkFontTopNav, animationCondition }) =>
      darkFontTopNav || animationCondition ? 'currentColor' : 'white',
    [theme.breakpoints.down('sm')]: {
      color: theme.palette.common.black,
    },
  },
}))

const NavigationItems = ({
  navItems,
  handleSearchOpen,
  searchOpen,
  handleActiveNav,
  activeNav,
  animationCondition,
  darkFontTopNav,
}: INavigationItems) => {
  const classes = useStyles({ darkFontTopNav, animationCondition })
  return (
    <>
      {!searchOpen ? (
        <>
          <Grid item sm={6} md={6} lg={6}>
            <ul className={classes.navItemsWrapper}>
              {navItems &&
                navItems.map((navItem: INavItems, index: number) => (
                  <NavigationItem
                    key={index}
                    {...navItem}
                    index={index}
                    active={index === activeNav - 1}
                    isAnimated={animationCondition || darkFontTopNav}
                    onClick={handleActiveNav}
                  />
                ))}
            </ul>
          </Grid>
          <Grid
            item
            sm={4}
            md={4}
            lg={3}
            className={classes.rightNavigationWrapper}
          >
            <IconButton
              className={classes.searchButton}
              aria-label="Søg"
              id="search-button"
              data-testid="search-button"
              onClick={handleSearchOpen}
            >
              <SvgIcon
                className={classes.searchIcon}
                component={iconsMap['Search']}
                viewBox="0 0 48 48"
              />
            </IconButton>
            <RightNavigationItems
              isAnimated={animationCondition || darkFontTopNav}
            />
          </Grid>
        </>
      ) : (
        <Button
          onClick={handleSearchOpen}
          variant="text"
          endIcon={
            <SvgIcon component={iconsMap['Close']} viewBox="0 0 48 48" />
          }
          id="close-search"
          data-testid="close-search"
        >
          Luk søgning
        </Button>
      )}
    </>
  )
}

export default NavigationItems
