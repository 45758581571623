import React from 'react'
import GatsbyLink from 'gatsby-link'
import { ILinkConverter } from './types'
import { ConditionalWrapper } from '../../utils'
import getIdFromString from '../../utils/getIdFromString'

// MUI
import Link from '@material-ui/core/Link'
import Tooltip from '@material-ui/core/Tooltip'

const LinkConverter = ({
  linkHref,
  linkTitle,
  target,
  children,
  className,
  tabIndex,
  onKeyDown,
  isTooltip,
  onClick,
}: ILinkConverter) => {
  const tooltipDescription = linkHref.replace(/(^\w+:|^)\/\//, '') // This removes "https://" from tooltip, since it has no actual value for the user

  const cleanUrl =
    linkHref.startsWith('https://') || linkHref.startsWith('http://')
      ? linkHref
      : 'https://tdc.dk' + linkHref

  const shouldUseRegularLink = (link: string) => {
    return (
      link.startsWith('http') ||
      link.startsWith('mailto:') ||
      link.startsWith('tel:')
    )
  }
  return (
    <ConditionalWrapper
      condition={isTooltip}
      wrapper={children => (
        <Tooltip title={tooltipDescription} placement="bottom-start">
          {children}
        </Tooltip>
      )}
    >
      {shouldUseRegularLink(cleanUrl) ? (
        <Link
          className={className || ''}
          href={cleanUrl}
          onKeyDown={onKeyDown}
          rel={target === '_blank' ? 'noreferrer noopener' : null}
          tabIndex={tabIndex || null}
          target={target || null}
          title={!isTooltip && linkTitle ? linkTitle : null}
          id={`link-external-${
            linkTitle ? getIdFromString(linkTitle) : getIdFromString(linkHref)
          }`}
          onClick={onClick}
        >
          {children}
        </Link>
      ) : (
        <Link
          className={className || ''}
          component={GatsbyLink}
          onKeyDown={onKeyDown}
          tabIndex={tabIndex || null}
          title={!isTooltip && linkTitle ? linkTitle : null}
          to={cleanUrl}
          id={`link-external-${
            linkTitle ? getIdFromString(linkTitle) : getIdFromString(linkHref)
          }`}
          onClick={onClick}
        >
          {children}
        </Link>
      )}
    </ConditionalWrapper>
  )
}

export default LinkConverter
