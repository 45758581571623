import { useEffect } from 'react'
import { IS_DOCUMENT } from '../../constants'
import { useConsent } from './useConsent'

type ChatWidget = {
  liveChatId?: string
  hasGenesysMessenger?: boolean
}

export const useChatWidget = (data: ChatWidget) => {
  if (!IS_DOCUMENT) return

  const liveChatId = data?.liveChatId
  const hasGenesysMessenger = data?.hasGenesysMessenger

  if (!data || (!liveChatId && !hasGenesysMessenger)) return

  const { cookie_cat_functional } = useConsent()

  // Toggle Genesys Messenger
  const togglegenesysMessenger = (visibility: boolean) => {
    const genesysMessengerScript = document.body.querySelector(
      'script[id^="genesys-chat-"]',
    )

    const genesysMessengerContainer = document.body.querySelector(
      'div[class="genesys-app"]',
    ) as HTMLDivElement
    const getAllGenesysContainers = document.body.querySelectorAll(
      'div[class="genesys-app"]',
    )

    // Remove excess containers
    if (getAllGenesysContainers.length > 1)
      getAllGenesysContainers[getAllGenesysContainers.length - 1].remove()

    // Toggle visibility
    genesysMessengerScript && genesysMessengerScript.remove()
    genesysMessengerContainer &&
      (genesysMessengerContainer.style.display = visibility ? 'block' : 'none')
  }

  // Remove Live Agent chat
  const removeCurrentLiveChat = () => {
    const liveChatScript = document.body.querySelector(
      'script[id^="livechatscript-"]',
    )

    if (!liveChatScript) return

    const chatId = liveChatScript?.id.replace('livechatscript-', '')
    const liveChatButton = document.body.querySelector(
      `div[id*="b_${chatId}"]`,
    ) as HTMLElement | null
    const liveChatIframeElement = document.body.querySelector(
      `iframe[id*="${chatId}"]`,
    )

    liveChatScript?.remove()
    liveChatButton?.remove()
    liveChatIframeElement?.remove()
  }

  const addChatWidget = () => {
    const chatWidgetScript = document.createElement('script')
    if (hasGenesysMessenger) {
      togglegenesysMessenger(true)
      // Generate Script for Genesys
      chatWidgetScript.id = `genesys-chat-${
        Math.floor(Math.random() * 1000) + 1
      }`
      chatWidgetScript.type = 'text/javascript'
      chatWidgetScript.innerHTML = `(function (g, e, n, es, ys) {
        g['_genesysJs'] = e;
        g[e] = g[e] || function () {
          (g[e].q = g[e].q || []).push(arguments)
        };
        g[e].t = 1 * new Date();
        g[e].c = es;
        ys = document.createElement('script'); ys.async = 1; ys.src = n; ys.charset = 'utf-8'; document.head.appendChild(ys);
      })(window, 'Genesys', 'https://apps.mypurecloud.de/genesys-bootstrap/genesys.min.js', {
        environment: 'prod-euc1',
        deploymentId: 'f46d34ee-5d4f-4754-b913-19d22bd13b32'
      });`
    } else {
      // Generate Script for LiveAgent chat
      chatWidgetScript.id = `livechatscript-${liveChatId}`
      chatWidgetScript.type = 'text/javascript'
      chatWidgetScript.innerHTML = `(function(d, src, c) { var t=d.scripts[d.scripts.length - 1],s=d.createElement('script');s.id='la_x2s6df8d';s.async=true;s.src=src;s.onload=s.onreadystatechange=function(){var rs=this.readyState;if(rs&&(rs!='complete')&&(rs!='loaded')){return;}c(this);};t.parentElement.insertBefore(s,t.nextSibling);})(document,
      'https://tdc.ladesk.com/scripts/track.js', function(e){ LiveAgent.createButton('${liveChatId}', e); });`
    }
    document.body.appendChild(chatWidgetScript)
  }

  useEffect(() => {
    if (!cookie_cat_functional) return
    // Add the chat widget if user consent is granted
    addChatWidget()

    return () =>
      hasGenesysMessenger
        ? togglegenesysMessenger(false)
        : removeCurrentLiveChat() //Cleanup
  }, [cookie_cat_functional, liveChatId, hasGenesysMessenger])
}
