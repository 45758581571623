import React, { ReactElement, useEffect } from 'react'
import { Container, makeStyles, Theme } from '@material-ui/core'
import { LoginPopup } from '../components/LoginPopup'
import { useRootStyles } from '../modules/root-styles/store/root-styles'
import { RootStyles } from '../modules/root-styles'
import { NotificationMessages } from '../components/NotificationMessages/NotificationMessages'
import { useLocation, Location } from '@reach/router'
import { useBasket } from '../modules/basket'
import { MiniBasketWrapper } from '../components/MiniBasketWrapper/MiniBasketWrapper'
import { ScrollPositionHandler } from '../components/ScrollPositionHandler/ScrollPositionHandler'
import TdcLayout from '../components/Layout'
import { usePageProvider } from '../context/PageProvider'
import { BasketErrorHandler } from '../components/BasketErrorHandler/BasketErrorHandler'

type ILayoutProps = {
  children: React.ReactElement | React.ReactElement[]
}

const useStyles = makeStyles<Theme, RootStyles>(theme => ({
  root: {
    minHeight: theme.spacing(60),
    marginTop: theme.spacing(7.75),
    paddingTop: theme.spacing(2.25),
    paddingBottom: theme.spacing(5),

    backgroundColor: props =>
      props.backgroundColor === 'default'
        ? 'initial'
        : theme.palette.grey['50'],

    [theme.breakpoints.up('md')]: {
      paddingTop: theme.spacing(4.75),
      marginTop: theme.spacing(10.25),
    },
  },
}))

const RootStyleControl = (): ReactElement => {
  const location = useLocation()
  const rootStyles = useRootStyles()
  const pagesWithSecondaryBackground = [
    '/kurv',
    '/checkud',
    '/betaling-godkendt',
  ]

  useEffect(() => {
    if (
      pagesWithSecondaryBackground.some(route => route === location.pathname)
    ) {
      rootStyles.changeBackgroundColor('secondary')
    } else {
      rootStyles.changeBackgroundColor('default')
    }
  }, [location.pathname])

  return <></>
}

export default function Layout({ children }: ILayoutProps): ReactElement {
  const rootStyle = useRootStyles()
  const classes = useStyles(rootStyle)

  const basket = useBasket()
  const location = useLocation()

  const { setAlertValue, setDarkFontTopNavValue } = usePageProvider()

  useEffect(() => {
    setAlertValue(null)
    setDarkFontTopNavValue(true)
  }, [])

  useEffect(() => {
    basket.closeMiniBasket()
  }, [location.pathname])

  return (
    <TdcLayout showSplitMenu pageVariant="SMB">
      <section className={classes.root}>
        <Location>
          {({ location }) => (
            <Container maxWidth="lg">
              <RootStyleControl />
              <ScrollPositionHandler />
              <BasketErrorHandler />
              {children}
              {location.pathname != '/kurv' &&
                location.pathname != '/checkud' &&
                location.pathname != '/betaling-godkendt' && (
                  <MiniBasketWrapper />
                )}
              <LoginPopup />
              <NotificationMessages />
            </Container>
          )}
        </Location>
      </section>
    </TdcLayout>
  )
}
