import React from 'react'
import { IMegaMenuLinks } from '../MegaMenu.types'
import ParrotfishIcon from '../../../../ParrotfishIcon'
import { Link } from 'gatsby'

//Mui Components
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(({ palette, breakpoints }) => ({
  root: {
    textDecoration: 'none',
    outline: 'none',

    '&:hover $card': {
      borderColor: palette.primary.main,
      boxShadow: `0px 10px 15px rgba(25, 34, 40, 0.05), 0px 2px 6px rgba(25, 34, 40, 0.05)`,
    },
    '&:focus $card': {
      borderColor: palette.primary.main,
      boxShadow: `0px 10px 15px rgba(25, 34, 40, 0.05), 0px 2px 6px rgba(25, 34, 40, 0.05)`,
    },
  },
  card: {
    width: '100%',
    border: ({ small }: IMenuItemCard) =>
      !small ? `solid 1px ${palette.grey[300]}` : null,
    height: '100%',
  },
  cardContent: {
    width: '100%',
    paddingTop: ({ small }: IMenuItemCard) => (!small ? 44 : null),
    padding: 20,
    paddingBottom: 20,
    height: '100%',
    display: ({ small }: IMenuItemCard) => (small ? 'flex' : 'block'),
    alignItems: 'center',

    '&:last-child': {
      paddingBottom: 20,
    },

    [breakpoints.down('sm')]: {
      display: 'flex !important',
      padding: '20px !important',
      alignItems: 'flex-start',
    },
  },
  icon: {
    width: ({ small }: IMenuItemCard) => (small ? 20 : 40),
    height: ({ small }: IMenuItemCard) => (small ? 20 : 40),
    color: palette.primary.main,
    fill: palette.primary.main,
    marginBottom: ({ small }: IMenuItemCard) => (!small ? 24 : null),
    marginRight: ({ small }: IMenuItemCard) => (small ? 12 : null),

    [breakpoints.down('sm')]: {
      width: ({ small }: IMenuItemCard) => (small ? 22 : 22),
      height: ({ small }: IMenuItemCard) => (small ? 22 : 22),
      marginBottom: 0,
      marginRight: 18,
    },
  },
  title: {
    marginBottom: ({ small }: IMenuItemCard) => (!small ? 8 : null),
    fontSize: ({ small }: IMenuItemCard) => (small ? 14 : null),
  },
  description: {
    display: ({ small }: IMenuItemCard) => (small ? 'none' : 'block'),
  },
}))

interface IMenuItemCard extends IMegaMenuLinks {
  small?: boolean
}

const MenuItemCard = ({
  linkText,
  href,
  linkdescription,
  icon,
  small,
}: IMenuItemCard) => {
  const classes = useStyles({ small })

  const cleanUrl = !href.startsWith('https://tdc.dk')
    ? 'https://tdc.dk' + href
    : href

  return (
    <Link activeClassName="active-link" className={classes.root} to={cleanUrl}>
      <Card className={classes.card} elevation={0}>
        <CardContent className={classes.cardContent}>
          {icon && (
            <ParrotfishIcon
              className={classes.icon}
              iconImage={icon.iconImage}
            />
          )}
          <div>
            <Typography className={classes.title} variant="subtitle1">
              {linkText}
            </Typography>
            <Typography className={classes.description} variant="caption">
              {linkdescription}
            </Typography>
          </div>
        </CardContent>
      </Card>
    </Link>
  )
}

export default MenuItemCard
