const productPagePrefix = '/produkt'

export const routes = {
  root: { route: '/' },
  home: { route: '/', title: 'Webshop' },
  basket: { route: '/kurv', title: 'Din bestilling' },
  checkout: { route: '/checkud' },
  orderConfirmation: { route: '/betaling-godkendt' },
  orderCancelled: { route: '/betaling-afvist' },
  productPage: {
    route: `${productPagePrefix}/:productId`,
    build: (
      productId: number | string,
      productName?: string,
      variantName?: string,
    ): string => `/produkt/${productId}`,
    queryParams: { productId: 'productId' as const },
  },
}
