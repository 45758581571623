import React, { useEffect, useState } from 'react'
import ReactMarkdown from 'react-markdown'
import { Variants } from '@tdcerhverv/mui-theme'

//MUI
import { makeStyles, useTheme } from '@material-ui/core/styles'
import Alert from '@material-ui/lab/Alert'
import AlertTitle from '@material-ui/lab/AlertTitle'
import IconButton from '@material-ui/core/IconButton'
import Link from '@material-ui/core/Link'
import Tooltip from '@material-ui/core/Tooltip'
import SvgIcon from '@material-ui/core/SvgIcon'
import { useMediaQuery } from '@material-ui/core'

// Icons
import Close from '@tdcerhverv/parrotfish/dist/icons/Icons/Close.svg'

//Components & Context
import {
  getSessionStorage,
  setSessionStorage,
} from '../../utils/sessionStorage'
import { IAlertBanner, IMarkdownText } from './AlertBanner.types'

const useStyles = makeStyles({
  alertBanner: {
    paddingRight: 32,
  },
})

const AlertBanner = ({
  inline = false,
  closable = true,
  ...props
}: IAlertBanner) => {
  const alertBannerData = props
  const bannerId = alertBannerData?.id
  const [alertClosed, setAlertClosed] = useState<boolean>(false)
  const isMobile = useMediaQuery(useTheme().breakpoints.down('sm'))

  useEffect(() => {
    const currentSession = getSessionStorage('alertBanner-' + bannerId)
    if (currentSession) {
      setAlertClosed(true)
    }
  }, [alertBannerData])

  const handleAlertClose = () => {
    setAlertClosed(true)
    setSessionStorage('alertBanner-' + bannerId, 'true')
  }

  const classes = useStyles()
  const markdownText =
    (alertBannerData?.description as IMarkdownText)?.description ||
    (alertBannerData?.description as string)

  return (
    !!alertBannerData?.severity &&
    !alertClosed && (
      <Alert
        className={`${(isMobile || inline) && Variants.Alert.Inline} ${
          classes.alertBanner
        }`}
        severity={alertBannerData.severity || 'info'}
        {...(closable
          ? {
              action: (
                <IconButton
                  aria-label="close"
                  color="inherit"
                  size="small"
                  onClick={() => {
                    handleAlertClose()
                  }}
                >
                  <SvgIcon
                    component={Close}
                    fontSize="inherit"
                    viewBox={'0 0 48 48'}
                  />
                </IconButton>
              ),
            }
          : {})}
      >
        {alertBannerData.title && (
          <AlertTitle>{alertBannerData.title}</AlertTitle>
        )}
        <ReactMarkdown
          components={{
            a: ({ children, href }) => {
              const url = href.replace(/(^\w+:|^)\/\//, '')
              return (
                <Tooltip title={url}>
                  <Link href={href}>{children}</Link>
                </Tooltip>
              )
            },
          }}
        >
          {markdownText}
        </ReactMarkdown>
      </Alert>
    )
  )
}

export default AlertBanner
