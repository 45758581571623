import React from 'react'

import DOMPurify from 'dompurify'

// Utils
import removeHttpPrefix from '../../../../utils/removeHttpsPrefix'

// MUI
import { makeStyles } from '@material-ui/core/styles'
import Chip from '@material-ui/core/Chip'
import Divider from '@material-ui/core/Divider'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import LinkConverter from '../../../LinkConverter'

interface ISearchResultItemStyles {
  filtersLoading: boolean
}

const useStyles = makeStyles(({ palette, breakpoints }) => ({
  resultItemContainer: {
    display: ({ filtersLoading }: ISearchResultItemStyles) =>
      filtersLoading ? 'none' : 'block',
  },
  resultItemWrapper: {
    padding: '24px 0',
    textDecoration: 'none',
  },
  pointOnHover: {
    cursor: 'pointer',
  },
  resultTitle: {
    color: palette.common.black,
  },
  resultLinkWrapper: {
    width: 'fit-content',
    margin: '4px 0 8px 0',
    color: palette.primary.main,
    '&:hover': {
      color: palette.primary.light,
    },
    '&:active': {
      color: palette.primary.dark,
    },
  },
  resultLink: {
    display: 'block',
    textDecoration: 'none',
    [breakpoints.down('xs')]: {
      wordBreak: 'break-word',
    },
  },
  resultDescriptionWrapper: {
    color: palette.common.black,
    maxWidth: 500,
    [breakpoints.down('xs')]: {
      width: 244,
    },
  },
  resultDescription: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: ' -webkit-box',
    maxHeight: 60,
    '-webkit-line-clamp': 3,
    '-webkit-box-orient': 'vertical',
  },
  tagChipWrapper: {
    textAlign: 'right',
    '& .MuiChip-root': {
      fontSize: 12,
      padding: 0,
      height: 24,
    },
    '& .MuiChip-label': {
      padding: '0 10px',
    },
  },
}))
export default function SearchResultItem({
  item,
  index,
  filtersLoading,
  onLinkClick,
}) {
  const sanitizedHighlights = searchResult => ({
    __html: DOMPurify.sanitize(
      searchResult.Fields.Description?.Highlights.join('...'),
    ),
  })
  const classes = useStyles({ filtersLoading: filtersLoading })

  return (
    <div className={classes.resultItemContainer}>
      {index !== 0 && <Divider />}
      <LinkConverter
        linkHref={item.Fields.Url.Value}
        className={classes.resultLink}
        onClick={() =>
          onLinkClick(item.Fields.Url.Value, item.Fields.Title?.Value)
        }
      >
        <Grid
          container
          justifyContent="space-between"
          className={classes.resultItemWrapper}
        >
          <Grid className={classes.pointOnHover} item xs={9}>
            <Typography className={classes.resultTitle} variant="h5">
              {item.Fields.Title?.Value}
            </Typography>
            <div className={classes.resultLinkWrapper}>
              <Typography variant="body2">
                {removeHttpPrefix(item.Fields.Url.Value)}
              </Typography>
            </div>
            <div className={classes.resultDescriptionWrapper}>
              <Typography
                variant="body2"
                className={classes.resultDescription}
                dangerouslySetInnerHTML={sanitizedHighlights(item)}
              />
            </div>
          </Grid>
          <Grid item className={classes.tagChipWrapper} xs={3}>
            <Chip label={item.Fields.Category?.Value}></Chip>
          </Grid>
        </Grid>
      </LinkConverter>
    </div>
  )
}
