import {
  ERHVERVSCENTER_API_DEV,
  ERHVERVSCENTER_API_PROD,
  PUBLIC_SHOP_API_DEV,
  PUBLIC_SHOP_API_PROD,
  IS_PROD,
} from '../../constants'

export const getErhvervsCenterAPIUrl = (): string => {
  if (IS_PROD !== 'true') return ERHVERVSCENTER_API_DEV
  return ERHVERVSCENTER_API_PROD
}

export const getPublicShopAPIUrl = (): string => {
  if (IS_PROD !== 'true') return PUBLIC_SHOP_API_DEV
  return PUBLIC_SHOP_API_PROD
}
