import React, { useEffect, useRef } from 'react'
import { IMegaMenu } from '.'

import { iconsMap } from '@tdcerhverv/parrotfish/dist/icons/icons'
import { AllFocusableElements, IMegaMenuLinks } from './MegaMenu.types'
import MenuItemCard from './components/MenuItemCard'
import LinkConverter from '../../../LinkConverter'

//MUI components
import { makeStyles } from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'
import IconButton from '@material-ui/core/IconButton'
import SvgIcon from '@material-ui/core/SvgIcon'
import Typography from '@material-ui/core/Typography'
import Grid, { GridSize } from '@material-ui/core/Grid'
import Divider from '@material-ui/core/Divider'
import { useMediaQuery, useTheme } from '@material-ui/core'

const useStyles = makeStyles(({ breakpoints, typography }) => ({
  title: {
    ...typography.subtitle1,
    fontSize: 24,
    display: 'flex',
    alignItems: 'center',
    [breakpoints.down('sm')]: {
      fontSize: 18,
      fontWeight: 500,
      background: 'none',
      border: 0,
      padding: '0 12px',
      width: '100%',
      cursor: 'pointer',
    },
  },
  titleIcon: {
    display: 'none',
    width: 16,
    height: 16,
    marginRight: 10,
    [breakpoints.down('sm')]: {
      display: 'block',
    },
  },
  minorItemsWrapper: {
    marginTop: 28,
    [breakpoints.down('sm')]: {
      marginTop: 24,
    },
  },
  helpItemDivider: {
    margin: '28px 0',

    [breakpoints.down('sm')]: {
      margin: '24px 0',
    },
  },
  helpItemWrapper: {
    display: 'flex',
    alignItems: 'center',
    flexFlow: 'wrap',

    '& > *': {
      marginRight: 16,
    },
    [breakpoints.down('sm')]: {
      '& > *': {
        marginBottom: 8,
      },
      '& > *:first-child': {
        flex: '1 0 100%',
        marginBottom: 16,
      },
    },
  },
  questionIcon: {
    width: 16,
    height: 16,
    marginRight: 10,
  },
  [breakpoints.down('sm')]: {
    closeButton: {
      display: 'none',
    },
  },
  helpItemLink: {
    fontSize: 13,
  },
}))

const MegaMenu = ({ handleClose, navItem, refObject, active }: IMegaMenu) => {
  const { megaMenuItems, title, image } = navItem
  const ref = useRef(null)
  const isMobile = useMediaQuery(useTheme().breakpoints.down('sm'))
  const majorItems = megaMenuItems && megaMenuItems[0]
  const minorItems = megaMenuItems && megaMenuItems[1]
  const helpItems = megaMenuItems && megaMenuItems[2]

  useEffect(() => {
    if (!active) return
    const firstMenuCard: HTMLAnchorElement =
      ref.current.querySelector('a:first-child')
    const activeLink: HTMLAnchorElement =
      ref.current.querySelector('a.active-link')

    if (megaMenuItems && !isMobile) {
      if (activeLink) {
        activeLink.focus()
      } else {
        firstMenuCard && firstMenuCard.focus()
      }
      //refObject && active && trapFocusInsideElement(refObject) // TODO: https://jira.nuuday.dk/browse/DR-2710
    } else {
      firstMenuCard && firstMenuCard.blur()
    }
  }, [megaMenuItems, active])

  useEffect(() => {
    const allLinks: AllFocusableElements =
      ref.current.querySelectorAll('a, button')
    if (!active) {
      allLinks.forEach(link => link.setAttribute('tabindex', '-1'))
    } else {
      allLinks.forEach(link => link.setAttribute('tabindex', '0'))
    }
  }, [active])

  const classes = useStyles()
  return (
    <div ref={ref} style={{ display: active ? 'block' : 'none' }}>
      <Box mb={3} display="flex" justifyContent="space-between">
        {title && (
          <Typography
            component={isMobile ? 'button' : 'h2'}
            className={classes.title}
            onClick={() => isMobile && handleClose(null)}
          >
            {isMobile ? (
              <>
                <SvgIcon
                  className={classes.titleIcon}
                  component={iconsMap['Expand_left']}
                  viewBox="0 0 48 48"
                />
                <span>{title}</span>
              </>
            ) : (
              title
            )}
          </Typography>
        )}
        <IconButton
          className={classes.closeButton}
          onClick={() => handleClose(null)}
        >
          <SvgIcon
            style={{ width: 16, height: 16 }}
            component={iconsMap['Close']}
            viewBox="0 0 48 48"
          />
        </IconButton>
      </Box>
      <Grid container spacing={4}>
        <Grid item sm={12} md={image ? 9 : 12} lg={image ? 9 : 12}>
          <Grid container spacing={2}>
            {majorItems &&
              majorItems?.links?.map((link: IMegaMenuLinks, index: number) => (
                <Grid
                  xs={12}
                  sm={6}
                  md={majorItems.links.length === 1 ? 6 : ('true' as GridSize)}
                  key={index}
                  item
                >
                  <MenuItemCard {...link} />
                </Grid>
              ))}
          </Grid>
          {minorItems && (
            <Grid className={classes.minorItemsWrapper} container spacing={0}>
              {minorItems?.links?.map((link: IMegaMenuLinks, index: number) => {
                const minorItemsLength = minorItems?.links?.length
                return (
                  <Grid
                    xs={minorItemsLength < 2 ? 12 : 6}
                    sm={6}
                    md={'auto'}
                    key={index}
                    item
                  >
                    <MenuItemCard small {...link} />
                  </Grid>
                )
              })}
            </Grid>
          )}
          {helpItems && (
            <>
              <Divider className={classes.helpItemDivider} />
              <div className={classes.helpItemWrapper}>
                <Box display="flex" alignItems="center">
                  <SvgIcon
                    className={classes.questionIcon}
                    component={iconsMap['Question']}
                    viewBox="0 0 48 48"
                  />
                  <Typography variant="subtitle1" style={{ fontSize: 13 }}>
                    Har du brug for hjælp?
                  </Typography>
                </Box>
                {helpItems.links.map((link: IMegaMenuLinks, index: number) => (
                  <LinkConverter
                    key={index}
                    linkHref={link.href}
                    className={classes.helpItemLink}
                  >
                    {link.linkText}
                  </LinkConverter>
                ))}
              </div>
            </>
          )}
        </Grid>
        {image && (
          <Grid item lg={3}>
            Picture
          </Grid>
        )}
      </Grid>
    </div>
  )
}

export default MegaMenu
