import React, { ReactElement } from "react"
import { Link } from "gatsby"

// MUI
import { makeStyles } from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'

const useStyles = makeStyles({
  titles: {
    marginBottom: 16,
  },
})

const NewsletterColumn = (): ReactElement => {
  const classes = useStyles()
  return (
    <Grid item xs={12} md={6} lg={3}>
      <Typography className={classes.titles} component="h2" variant="subtitle1">
        Tilmeld nyhedsbrev
      </Typography>
      <Typography component="p" variant="caption">
        Tilmeld dig nyhedsbrevet fra TDC Erhverv og få spændende og inspirerende
        viden og nyheder tilsendt på din e-mail.
      </Typography>
      <Box mt={3}>
        <Button component={Link} size="small" to="https://tdc.dk/nyhedsbrev">
          Tilmeld
        </Button>
      </Box>
    </Grid>
  )
}

export default NewsletterColumn
