export const vatNoticeContent = `Omvendt betatlingspligt for moms trådte i kraft i 2014 og påvirker virksomheders køb af bla. mobiltelefoner og bærbare computer.<br>
<br>
I forhold til dit køb på TDC.dk har den omvendte betalingspligt den betydning, at du ikke skal betale moms til TDC Erhverv for de varer, der er omfattet af den omvendte betalingspligt og anvendes i din virksomhed. I stedet har du som køber selv ansvar for at afregne momsen på disse varer til Skattemyndighederne.<br>
<br>
De nye regler kan virke besværlige, men vi har hos TDC.dk gjort vores bedste for, at det er så nemt som muligt for dig som kunde. Når du handler på TDC.dk kan du nemt se hvilke varer, der er omfattet af omvendt betalingsplligt i din indkøbskurv og det vil også fremgå af din ordrebekræftelse og faktura.<br>
<br>
De varer der er omfatte af omvendt betalingspligt er:<br>
Mobiltelefoner<br>
Tablets<br>
Bærbare computere<br><br>
`;
