import { useEffect, useState } from 'react'
import { IS_BROWSER } from '../../constants'
import { useLocation } from '@reach/router'

export const useScrollToElement = () => {
  const [elementPosition, setElementPosition] = useState<number>(0)
  const { hash } = useLocation()

  useEffect(() => {
    if (!hash) return
    const selector = hash.replace('#', '')
    const element = document.querySelector(
      `[data-scroll-id*='${selector}']`,
    ) as HTMLDivElement
    if (!IS_BROWSER && !element) return

    setTimeout(() => {
      const elPosition = element?.getBoundingClientRect().top
      const headerHeight =
        document?.getElementsByTagName('header')[0]?.offsetHeight
      const offsetPosition = elPosition + window.pageYOffset - headerHeight
      setElementPosition(offsetPosition)
    }, 500)
  }, [hash])

  useEffect(() => {
    if (!elementPosition || !(elementPosition > 0)) return
    window.scrollTo({
      top: elementPosition,
      left: 0,
      behavior: 'smooth',
    })
  }, [elementPosition, hash])
}
