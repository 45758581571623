import { IS_BROWSER } from '../../../constants'

export const setSessionStorage = (sessionName: string, value: string) => {
  if (IS_BROWSER) {
    return window.sessionStorage.setItem(sessionName, value)
  }
}
export const getSessionStorage = (sessionName: string) => {
  if (IS_BROWSER) {
    return window.sessionStorage.getItem(sessionName)
  }
}
