import React, { useState } from 'react'
import cookie from 'cookiejs'
import {
  Button,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  makeStyles,
} from '@material-ui/core'
import { translate } from '../../helpers/translate'
import { UnlockIcon } from '../../icons/Unlock'
import { IS_BROWSER, TDCE_LINK_DESTINATION } from '../../../constants'

const cookieName = 'publicShopLoginWarning'

const useStyles = makeStyles(theme => ({
  contentRoot: {
    maxWidth: 400,
  },
  text: {
    ...theme.typography.body2,
    marginBottom: theme.spacing(4),
  },
  button: {
    marginBottom: theme.spacing(2),
  },
}))

export const LoginPopup: React.FC = React.memo((): JSX.Element | null => {
  const dialogHasBeenSpawned = IS_BROWSER ? cookie(cookieName) : true
  const [open, setOpen] = useState(true)
  const classes = useStyles()

  if (dialogHasBeenSpawned) return null

  const onClose = (): void => {
    setOpen(false)
    cookie.set(cookieName, '1', { path: '/', expires: 31 })
  }

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>{translate('loginPopupTitle')}</DialogTitle>
      <DialogContent className={classes.contentRoot}>
        <DialogContentText
          className={classes.text}
          dangerouslySetInnerHTML={{ __html: translate('loginPopupText') }}
        />
        <Button
          component="a"
          variant="contained"
          color="primary"
          href={TDCE_LINK_DESTINATION}
          fullWidth
          className={classes.button}
          rel="noopener"
          size="large"
          target="_blank"
        >
          <UnlockIcon />
          &nbsp;
          {translate('loginPopupLinkTitle')}
        </Button>
        <Button
          variant="text"
          onClick={onClose}
          fullWidth
          className={classes.button}
          size="large"
        >
          {translate('loginPopupDismissTitle')}
        </Button>
      </DialogContent>
    </Dialog>
  )
})
